/* ==========================================================================
Block: Therapists
========================================================================== */
.therapists {
  padding-top: 35px;
  padding-bottom: 70px;
  outline: none;
  @include media-breakpoint-up(md) {
    padding-top: 55px;
    padding-bottom: 100px;
  }
  &__title-wrapper {
    margin-bottom: 55px;
    text-align: center;
    @include media-breakpoint-up(md) {
      margin-bottom: 110px;
    }
  }
  &__title {
    margin-bottom: 30px;
  }
  &__wrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    @include media-breakpoint-up(md) {
      flex-flow: row wrap;
      justify-content: center;
      margin: 0 -20px;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 -15px;
    }
    .therapist-card__item {
      margin-bottom: 30px;
      @include media-breakpoint-up(md) {
        margin: 0 20px;
        margin-bottom: 55px;
      }
      @include media-breakpoint-up(lg) {
        margin: 0 15px;
        margin-bottom: 55px;
      }
    }
  }
  &__button-wrapper {
    margin-top: 20px;
    text-align: center;
  }
  &__not-found {
    display: inline-block;
    width: 100%;
    font-weight: $font-weight-bold;
    font-size: $h2-font-size;
    text-align: center;
  }
}

/* ==========================================================================
Single: Therapist
========================================================================== */
.therapist {
  &__moto-quote {
    &::before {
      margin-right: 2px;
      content: '„';
    }
    &::after {
      margin-left: 2px;
      content: '“';
    }
  }
}

/* ==========================================================================
Block: Therapist card
========================================================================== */
.therapist-card {
  height: 100%;
  // ======================= Wrapper
  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0 7px 30px rgba(211, 155, 123, 0.3);
    @include media-breakpoint-up(lg) {
      justify-content: space-between;
      max-width: 355px;
      min-height: 523px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 340px;
    }
  }
  // ======================= Profile (Top)
  &__profile {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 29px; // approximate wave height
    padding: 15px 15px 0;
    background-color: $sand;
    border-radius: 12px;
    @include media-breakpoint-up(sm) {
      padding: 25px 25px 0;
    }
  }

  &__image-wrapper {
    position: relative;
    width: 92px;
    min-width: 92px;
    height: 107px;
    margin-right: 20px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  &__fill-iframe {
    width: 0;
    height: 0;
    padding: 0;
    background-color: transparent;
    border: 0;
  }
  &__play {
    position: absolute;
    right: -5px;
    bottom: -5px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: $primary;
    background-color: $white;
    border-radius: 99px;
    box-shadow: 0 7px 30px rgba(211, 155, 123, 0.3);
    transition: background-color 0.2s, color 0.2s;
    &:hover {
      color: $white;
      background-color: $primary;
    }
    &-icon {
      width: 9px;
      height: 9px;
    }
  }

  &__info {
    z-index: 2;
    display: block;
    flex: 1;
  }

  &__price {
    display: inline-block;
    margin: 0 45px 22px;
    color: $secondary;
    font-weight: 800;
    font-size: 18px;
    border-radius: 4px;
  }

  &__fullname-link {
    color: $body-color;
    transition: color 0.2s;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__fullname {
    display: block;
    font-weight: 800;
    font-size: 16px;
  }

  &__practice {
    display: block;
    font-weight: 700;
    font-size: 13px;
  }

  &__specialization {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: 15px;
    &-item {
      margin: 2px;
    }
  }

  &__wave {
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    width: 100%;
    fill: $sand;
    @include media-breakpoint-up(sm) {
      top: calc(100% - 19px);
      height: 54px;
    }
    @include media-breakpoint-up(lg) {
      top: calc(100% - 2px);
      height: auto;
    }
  }

  // ======================= Reviews (center 1)
  &__reviews {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 13px 45px;
    color: $secondary;
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    &-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: 0 10px;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    &-icon-wrapper {
      margin-right: 8px;
    }
    &-icon {
      width: 18px;
      height: 18px;
      fill: $secondary;
    }
  }

  // ======================= Quote (center 2)
  &__quote {
    margin-bottom: 8px;
    padding: 0 45px;
    color: $text-color-light;
    font-size: 16px;
    line-height: 26px;
  }

  &__links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 30px 25px;
    border-top: 2px solid $dawn-pink;

    // iPhone 5S and smaller
    @media screen and (max-width: 320px) {
      flex-flow: column nowrap;
    }

    .btn {
      // iPhone 5S and smaller
      @media screen and (max-width: 320px) {
        margin-bottom: 15px;
      }

      &:last-of-type {
        // iPhone 5S and smaller
        @media screen and (max-width: 320px) {
          margin-bottom: 0;
        }
      }
    }
  }


  // ======================= ajax loading styles
  &__item--loading {
    position: relative;
    filter: grayscale(1);
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba($body-bg, 0.3), rgba($body-bg, 0.5), rgba($body-bg, 0.9), rgba($body-bg, 0.5), rgba($body-bg, 0.3), rgba($body-bg, 0.5), rgba($body-bg, 0.9));
      background-size: 200% 200%;
      animation: waveLoading 0.8s linear infinite;
      content: '';
    }
    span,
    .therapist-card__image-wrapper {
      color: transparent;
      background-color: $gray-400;
    }
    .btn {
      color: transparent;
    }
    .icon {
      display: none;
    }
  }
}

/* ==========================================================================
Block: Therapist card
========================================================================== */
.therapist-recommend {
  flex-basis: 100%;
  max-width: 100%;
  margin: 30px 0 60px;
  text-align: center;
  @include media-breakpoint-up(md) {
    margin: 30px -10px 60px;
    padding: 0 10px;
  }
  @include media-breakpoint-up(lg) {
    margin: 20px -15px 75px;
    padding: 0 15px;
  }

  // ======================= Wrapper
  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 30px 25px;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0 7px 30px rgba(211, 155, 123, 0.3);
    @include media-breakpoint-up(lg) {
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      text-align: left;
    }
  }

  // ======================= Image
  &__image-wrapper {
    max-width: 250px;
  }
  &__image {
    max-width: 100%;
  }

  // ======================= Content
  &__content {
    max-width: 450px;
    padding: 30px 0;
    color: $secondary;
  }
  &__title {
    @include media-breakpoint-up(lg) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  // ======================= Link
  &__link {
    @include media-breakpoint-up(lg) {
      margin-right: 30px;
    }
    @include media-breakpoint-up(xl) {
      margin-right: 65px;
    }
  }
}


@keyframes waveLoading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
