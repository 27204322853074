.MuiTableCell-root,
.MuiBox-root .MuiBox-root div .MuiBox-root *,
.MuiTypography-root,
.MuiInputBase-input {
  font-family: $font-family-base !important;
  @include media-breakpoint-tablet() {
    font-family: $font-family-base !important;
  }
  @media screen and (max-width: 700px) {
    font-family: $font-family-base !important;
  }
}

/* ===================================================
   ==== Modifier: Client TimeTableCell  =======================
   =================================================== */
.time-table-cell {
  &__child-wrapper {
    width: 100px;
    text-align: center;
  }
  &__child {
    display: block;
    color: $light-brown-trans;
    text-align: center;
    transition: background-color 0.2s;
    &:hover {
      background-color: $light-body-bg;
    }
  }
}

/* ===================================================
   ==== Component: Calendar Active prebooking====
   =================================================== */
.calendar-box {
  &__root {
    min-height: 250px;
    margin: 0 0 30px 0;
    background-color: $white;
    border-radius: 12px;
    position: relative;

    @include media-breakpoint-tablet() {
      overflow: hidden;
    }

    &--prebooking {
      padding-bottom: 80px;


      @include media-breakpoint-tablet() {
        padding-bottom: 0;
      }
    }
    .loader--basic {
      position: absolute;
    }

    &--rebooking {
      min-height: 700px;
    }
  }
}

/* ===================================================
   ==== Component: ToolbarInfo  =======================
   =================================================== */
.toolbar-info {
  &__wrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  &__text {
    margin-left: 20px;
    font-weight: 900;
    font-size: $font-size-base;
    line-height: $line-height-md;
    letter-spacing: 0.02em;
  }
  @include media-breakpoint-tablet() {
    &__wrapper {
      padding: 0 26px;
      &--therapist {
        padding: 0;
      }
    }
    &__text {
      font-size: $font-size-base;
    }
  }
}

.rebooking {
  &__content {
    &--client-detail {
      .rebooking__buttons {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;

        button {
          margin-top: 20px;
        }
      }
    }
  }
}
