.user-devices {
  &__speaker {
    margin-top: 20px;
    @include media-breakpoint-tablet() {
      display: flex;
      align-items: center;
    }
    .user-devices__option {
      @include media-breakpoint-tablet() {
        margin-bottom: 0;
      }
    }
    // Temporary fix, should be deleted after speaker settings will work
    .user-devices__option-title {
      margin-bottom: 0;
    }
  }
  &__option {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 15px;
    @include media-breakpoint-tablet() {
      flex-flow: row nowrap;
      align-items: center;
    }

    &-title {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;

      @include media-breakpoint-tablet() {
        margin-bottom: 0;
        margin-right: 25px;
      }
    }
  }
  &__audio-test-btn {
    display: block;
    width: 100%;
    @include media-breakpoint-tablet() {
      width: auto;
    }
    &-icon {
      margin-left: 8px;
    }
  }
  &__select {
    flex: 1;
    padding: 0px;
    background-color: transparent;
    border: 0px;
    text-transform: none;
    font-size: 14px;

    @include media-breakpoint-tablet() {
      cursor: pointer;
      color: $text-color;
      border-radius: $border-radius;
    }

    &:focus {
      outline: 0;
      border: 0px;
    }

    .react-select__single-value {
      @include media-breakpoint-tablet() {
        color: $text-color;
      }
    }

    .react-select__value-container {
      @include media-breakpoint-tablet() {
        padding: 0px;
        padding-right: 2px;
      }
    }

    .react-select__indicator-separator {
      @include media-breakpoint-tablet() {
        margin-top: 0px;
        margin-bottom: 0px;
        display: none;
      }
    }

    .react-select__single-value {
      @include media-breakpoint-tablet() {
        width: 100%;
        text-align: right;
      }
    }

    .react-select__indicator {
      @include media-breakpoint-tablet() {
        padding: 0px;
      }
    }

    .react-select__control {
      @include media-breakpoint-tablet() {
        padding: 0px;
        min-height: initial;
        background-color: transparent;
        border: 0px;
        text-align: right;
        cursor: pointer;
      }

      &--menu-is-open {
        background-color: $white;
        border-color: $primary;
        box-shadow: 0 0 0 1px $primary;

        &:focus {
          @include media-breakpoint-tablet() {
            outline: 0;
            border: 0px;
          }
        }
      }
    }
  }
  &__camera {
    margin-bottom: 15px;
    margin-top: 20px;
    @include media-breakpoint-tablet() {
      margin-top: 0px;
    }
  }
  &__video-wrapper {
    position: relative;
  }
  &__video {
    width: 100%;
    border-radius: $border-radius;
    overflow: hidden;
    min-height: initial;
    height: auto;
    &--paused {
      height: 256px;
      width: 100%;
      background-color: $light;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 61px;

      @include media-breakpoint-tablet() {
        height: 382px;
      }
    }
  }
  &__video-switcher {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
    padding-left: 0px;
    padding-right: 0px;
    width: 41px;
  }
}
