.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-top: $sidebar-content-mobile-offset;
  background-color: $sidebar-bg;
  transform: translateX(100%);
  transition: transform 0.2s;
  @include media-breakpoint-tablet() {
    max-width: $sidebar-max-width;
  }
  @include media-breakpoint-desktop() {
    max-width: $sidebar-max-width-desktop;
  }
  @include media-breakpoint-big-tablet() {
    left: 0;
    padding-top: 0;
    transform: none;
  }
  &__logo {
    display: none;
    border-radius: 0;
    @include media-breakpoint-big-tablet() {
      display: block;
      width: 100%;
      height: $sidebar-logo-height;
      padding: $sidebar-padding;
      padding-bottom: $sidebar-padding-y - 10px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  &__content {
    flex: 1;
    max-height: $sidebar-content-mobile-height;
    overflow-y: auto;
    @include media-breakpoint-big-tablet() {
      max-height: $sidebar-content-height;
    }
  }
  &__navigation {
    margin: $sidebar-navigation-offset 0;
    padding: 0;
    list-style-type: none;
  }
  &__impersonate {
    display: block;
    text-align: center;
    color: $error;
  }
  &__footer {
    height: $sidebar-footer-height;
    padding: $sidebar-footer-offset 0;
    text-align: center;
  }
}

.sidebar--mobile-open {
  z-index: 1301; // one higher than calendar overlay
  transform: translateX(0);
}
