.mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: $mobile-header-height;
  background-color: $body-bg;
  transition: background-color 0.2s, box-shadow 0.2s;
  @include media-breakpoint-big-tablet() {
    display: none;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: $mobile-header-max-width;
    height: $mobile-header-height;
    margin: 0 auto;
    padding: $mobile-header-padding;
  }
  &__logo {
    width: 100%;
    max-width: 110px;
    padding: 0;
    background: transparent;
    border-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }
  &__button {
    color: $text-color-light;
    text-transform: none;
  }
  &__icon {
    margin-right: 2px;
    margin-left: -2px;
    vertical-align: text-top;
  }
  &__menu-toggle {
    display: inline-flex;
    margin-left: 25px;
    vertical-align: middle;
    @media screen and (max-width: 320px) {
      margin-left: 5px;
    }
  }
}

.mobile-header--sidebar-open {
  z-index: 1302; // one higher than sidebar
  background-color: $white;
  box-shadow: 0 0 5px 2px rgba($brown, 0.2);
}
