.CybotCookiebotDialogActive ~ * .cookiebot-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0;
  z-index: 0;
  display: none;
}

.CybotCookiebotDialogBodyButton {
  border-radius: 12px !important;
  border-color: $primary !important;
}

#CybotCookiebotDialogBodyButtonDecline,
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialogBodyLevelButtonCustomize {
  color: $primary !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: $primary !important;
}

#CybotCookiebotDialogBodyContentText {
  a {
    color: $primary !important;
  }
}

#CybotCookiebotDialogBodyContentTitle {
  font-size: 26px !important;
  line-height: 36px !important;
  font-weight: 700 !important;
  color: $secondary !important;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    background: url('/images/cookie.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    margin-right: 20px;
  }
}
#CybotCookiebotDialogNav,
#CybotCookiebotDialog .CybotCookiebotScrollContainer {
  border-bottom: 2px solid $body-bg !important;
  color: $secondary;
}
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
  color: $secondary !important;
}
#CybotCookiebotDialogHeader,
#CybotCookiebotDialogPoweredByText,
#CybotCookiebotDialogPoweredbyCybot {
  display: none !important;
}
#CybotCookiebotDialogBodyButtonDecline {
  display: block !important;
}
