.counselling {
  &__content {
    h1,
    h2,
    h3,
    h4,
    h5 {
      // color: $bordeaux;
      margin: 23px 0 15px;
      font-weight: 700;

      @include media-breakpoint-big-tablet() {
        margin: 52px 0 25px;
      }
    }

    p {
      font-size: $font-size-md;
      line-height: 30px;
    }

    li {
      padding: 6px 0;
    }

    ol {
      list-style: none;
      counter-reset: li-counter;
      margin: 30px 0 30px;
      padding: 0;
      position: relative;

      > li {
        padding: 0;
        counter-increment: li-counter;
        margin: 24px 0;
        padding-left: 46px;

        &:before {
          content: counter(li-counter);
          color: $secondary;
          font-size: 14px;
          font-weight: bold;
          width: 26px;
          height: 26px;
          border: 2px solid $secondary;
          border-radius: 50px;
          display: inline-block;
          text-align: center;
          padding: 1px;
          margin-right: 20px;
          position: absolute;
          left: 0;
        }
      }

      ol {
        padding-left: 32px;
        margin-top: 20px;

        li {
          padding: 0;
          margin: 12px 0;

          &:before {
            border: none;
          }
        }
      }
    }

    table {
      margin-top: 32px;
      text-align: center;
      color: $secondary;
      font-size: 16px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      overflow: hidden;
    }

    table thead {
      background-color: white;
      font-size: 18px;
      font-weight: 700;
    }

    table thead th {
      display: table-cell;
      padding: 24px 32px;
    }

    table tbody {
      background-color: $body-bg;
    }

    table tbody td {
      display: table-cell;
      padding: 24px 32px;
      border: 2px solid $light;
    }

    table tbody tr:first-child td:first-child {
      border-top-left-radius: 16px;
    }

    table tbody tr:first-child td:last-child {
      border-top-right-radius: 16px;
    }

    table tbody tr:last-child td:first-child {
      border-bottom-left-radius: 16px;
    }

    table tbody tr:last-child td:last-child {
      border-bottom-right-radius: 16px;
    }

    img {
      border-radius: 12px;
    }
  }
}
