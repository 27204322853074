.payment-buttons {
  &__payment {
    display: flex;
    justify-content: space-around;
    @include media-breakpoint-tablet() {
      margin-top: 20px;
    }
    &-button {
      width: 100%;
      margin-top: 20px;
    }
  }
  &__row {
    width: 100%;
    align-items: center;
  }
  &__code {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    button {
      font-size: 16px;
    }
  }
  &__code--voucher {
    margin-top: 0;
  }
}
