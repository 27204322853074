.select2-container {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  vertical-align: middle;
  min-width: 240px;
  text-align: center;
  .select2-selection {
    position: relative;
    padding: 0 20px;
    transition: background-color 0.2s;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    color: $body-color;
    background-color: $light;
    &::after {
      content: '';
      position: absolute;
      top: 16px;
      right: 16px;
      width: 10px;
      height: 10px;
      background-image: url('data:image/svg+xml,%3Csvg fill="none" viewBox="0 0 8 5" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M4.05 3.13L1.94.26A.65.65 0 001.43 0 .63.63 0 00.8.62C.8.77.84.88.92 1l2.61 3.44c.12.15.28.29.52.29s.4-.14.52-.29L7.2 1c.08-.1.11-.22.11-.37A.63.63 0 006.68 0a.65.65 0 00-.5.26L4.04 3.13z" fill="%23473134"/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    &__clear {
      position: absolute;
      top: 0;
      left: 16px;
    }
  }
  .select2-selection--multiple {
    .select2-selection {
      &__clear {
        top: 4px;
      }
    }
  }
  .select2-search__field {
    cursor: pointer;
  }
}

.select2-container .select2-selection:hover,
.select2-container.select2-container--open .select2-selection {
  background-color: darken($light, 4%);
}

.select2-container .select2-selection--single {
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  &:focus {
    outline: none;
  }
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-right: 20px;
  padding-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select2-container[dir='rtl'] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  line-height: 24px;
  padding-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  padding: 0;
  font-size: 100%;
  border: none;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  position: absolute;
  top: 10px;
  left: -100000px;
  z-index: 1051;
  display: block;
  padding: 14px 0;
  box-sizing: border-box;
  width: 100%;
  background-color: $white;
  border-radius: 8px;
  text-align: left;
  overflow: hidden;

}

.select2-results {
  display: block;
}

.select2-results__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select2-results__option {
  position: relative;
  padding: 4px 20px;
  user-select: none;
  transition: background-color 0.2s;
  font-size: $font-size-sm;
  &::before {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-bottom: 2px;
    vertical-align: middle;
    margin-right: 10px;
    background-image: url('data:image/svg+xml,%3Csvg fill="none" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"%3E%3Crect x="1" y="1" width="17" height="17" rx="3" stroke="%23490811" stroke-width="2"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: block;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
}

.select2-container--default .select2-selection--single {
  border-radius: 40px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 44px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  display: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: -2px;
  margin-left: -4px;
  border-color: #888 transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0;
}

.select2-container--default[dir='rtl'] .select2-selection--single .select2-selection__arrow {
  right: auto;
  left: 1px;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888;
  border-width: 0 4px 5px;
}

.select2-container--default .select2-selection--multiple {
  border-radius: 40px;
  cursor: pointer;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 5px 15px 0;
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  margin-top: 5px;
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
  font-weight: bold;
  cursor: pointer;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  float: left;
  margin-top: 5px;
  margin-right: 5px;
  padding: 0 5px;
  background-color: $light;
  border-radius: 4px;
  cursor: default;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  display: inline-block;
  margin-right: 5px;
  color: #999;
  font-weight: bold;
  cursor: pointer;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice {
  margin-right: auto;
  margin-left: 5px;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: auto;
  margin-left: 2px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  color: $body-color;
  font-size: $font-size-sm;
  text-align: center;
  font-weight: $font-weight-bold;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
  color: $body-color;
  font-size: $font-size-sm;
  text-align: center;
  font-weight: $font-weight-bold;
  &::placeholder {
    color: inherit;
  }
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: $scrollbar-width;
    height: $scrollbar-height;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollbar-track-bg;
    border-radius: $scrollbar-border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-handle-bg;
    border-radius: $scrollbar-border-radius;
    &:hover {
      background-color: $scrollbar-handle-hover-bg;
    }
  }
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  &::before {
    background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"%3E%3Crect width="19" height="19" rx="4" fill="%23FF8F67"/%3E%3Cpath d="M13.9 6.22a.75.75 0 00-1.07.02l-3.48 4.43-2.09-2.1A.75.75 0 006.2 9.63l2.65 2.65a.75.75 0 001.08-.02l3.99-5a.75.75 0 00-.01-1.04z" clip-rule="evenodd" fill="%23fff" fill-rule="evenodd"/%3E%3C/svg%3E');
  }
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $light;
}

.select2-container--default .select2-results__group {
  display: block;
  padding: 6px;
  cursor: default;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  background-image: -webkit-linear-gradient(top, white 50%, #eee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eee 100%);
  background-repeat: repeat-x;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 44px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  margin-right: 10px;
  font-weight: bold;
  cursor: pointer;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  height: 26px;
  background-color: #ddd;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: -2px;
  margin-left: -4px;
  border-color: #888 transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0;
}

.select2-container--classic[dir='rtl'] .select2-selection--single .select2-selection__arrow {
  right: auto;
  left: 1px;
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888;
  border-width: 0 4px 5px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  background-image: -webkit-linear-gradient(top, white 0%, #eee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eee 50%);
  background-repeat: repeat-x;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  background-image: -webkit-linear-gradient(top, #eee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eee 50%, white 100%);
  background-repeat: repeat-x;
  border-bottom: none;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  cursor: text;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  margin: 0;
  padding: 0 5px;
  list-style: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  float: left;
  padding: 0 5px;
  background-color: $light;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  display: inline-block;
  margin-right: 5px;
  color: #888;
  font-weight: bold;
  cursor: pointer;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir='rtl'] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-right: auto;
  margin-left: 5px;
}

.select2-container--classic[dir='rtl'] .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: auto;
  margin-left: 2px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 0;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  color: white;
  background-color: #3875d7;
}

.select2-container--classic .select2-results__group {
  display: block;
  padding: 6px;
  cursor: default;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

// Custom

.select2-container.select2-container--focus .select2-selection--single,
.select2-container.select2-container--focus .select2-selection--multiple{
  background-color: $primary;
  color: white;

  &::after {
    filter: brightness(0) invert(1);
  }

  .select2-search__field{
    color: white;
    cursor: pointer;
  }

  .select2-selection__choice{
    background-color: lighten($primary, 5%);
  }

  .select2-selection__choice__remove{
    color: white;
  }
}