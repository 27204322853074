// https://stackoverflow.com/questions/77217749/how-to-add-animation-to-collapsible-element-shadcn
// https://www.radix-ui.com/primitives/docs/components/collapsible

@layer base {
    .CollapsibleContent {
    overflow: hidden;
    }
    .CollapsibleContent[data-state='open'] {
    animation: slideDown 400ms ease-out;
    }
    .CollapsibleContent[data-state='closed'] {
    animation: slideUp 400ms ease-out;
    }

    @keyframes slideDown {
    from {
        height: 0;
    }
    to {
        height: var(--radix-collapsible-content-height);
    }
    }

    @keyframes slideUp {
    from {
        height: var(--radix-collapsible-content-height);
    }
    to {
        height: 0;
    }
    }
}

