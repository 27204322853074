.mobile-calendar {
  &--bottom-border {
    border-bottom: 1px solid #fcf7f2;
  }
}

.mobile-header-nav {
  &__container {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    align-items: center;
    padding: 10px 12px 10px 12px;

    &--active {
      border-top-left-radius: $border-radius-lg;
      border-top-right-radius: $border-radius-lg;
      background: $secondary;
      color: $white;

      .mobile-header-nav__date,
      .btn-icon--primary {
        color: $white;
      }
    }
  }
  &__right-controls-wrapper {
    display: flex;
    .services-select {
      margin-right: 10px;
      .react-select__control {
        min-width: 0;
      }
    }
  }
  &__calendar-icon {
    color: $text-color;
  }
  &__navigation-button {
    border: none;
    background: transparent;
  }
  &__date {
    font-weight: bold;
    text-transform: uppercase;
    color: $text-color-light;
    font-size: 14px;
    @include media-breakpoint-tablet() {
      font-size: 18px;
    }
  }
  &__date-picker {
    .rdtPicker {
      right: 0;
    }
  }
}

.mobile-calendar-days {
  &__days {
    padding: 10px 12px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
  &__button {
    border-radius: 8px;
    padding: 8px;
    border: none;
    background: transparent;
    line-height: 16px;
    color: $text-color;
    text-transform: uppercase;

    &--active {
      background: $primary;
      color: $white;
    }
    &--disabled {
      color: #c4c4c4;
    }
  }
}

.mobile-calendar-appointments {
  &__wrapper {
    padding: 16px 12px;
  }
  &__active-day {
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    &--text {
      color: $text-color-light;
    }
  }
  &__appointments-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 0;
  }
  &__appointment {
    border-radius: 8px;
    background: $time-slot-bg;
    border: none;
    color: $white;
    font-weight: bold;
    min-width: 115px;
    padding: 5px 8px;

    &--reserved {
      background: $primary;
    }
  }
}
