.services-select.react-select {
  max-width: 300px;
  display: flex;
  .react-select {
    &__control {
      padding: 0;
      border-radius: 6px;
      &--is-focused {
        .react-select__single-value {
          .icon {
            color: white;
          }
        }
      }
    }
    &__value-container {
      padding: 8px;
      @include media-breakpoint-tablet() {
        justify-content: flex-start;
      }
      span {
        @include media-breakpoint-tablet() {
          display: block;
          margin-left: 6px;
        }

      }
      .mobile-header-nav__container--client & {
        span {
          display: none;
        }
      }
    }
    &__single-value {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transform: none;
      max-width: initial;


      .icon {
        color: $text-color;
        @media screen and (max-width: 768px) {
          margin-right: 15px;
        }
      }

      .mobile-header-nav__container--client & {
        .icon {
          @media screen and (max-width: 768px) {
            margin-right: 0;
          }
        }
      }
    }
    &__indicators {
      display: none;
      @include media-breakpoint-big-tablet() {
        display: block;
      }
    }
    &__indicator {
      position: relative;
    }
    &__menu {
      @media screen and (max-width: 767px) {
        min-width: 300px;
        left: 0;
      }
    }

    .mobile-header-nav__container--client & {
      @media screen and (max-width: 768px) {
        &__menu {
          right: 0;
          left: auto;
          transform: translate(50%, 0);
        }
      }
    }

  }
}
