// semantic colors
$primary: $peach-500;
$secondary: $petrol-500;
$tertiary: $yellow-500;

//Toastify
$toast-success: $petrol-500;
$toast-warning: $sand-700;
$toast-error: $peach-800;

// overriden colors
$text-color: $secondary;
$text-color-light: $secondary;
$body-bg: $sand-500;
$dawn-pink: #fff;
$light: #fff;
$alternative-bg: #fff;
$light-grey: $sand-600;
$aqua: $blue-500;
$light-brown-trans: $sand-700;
$session-box-bg: $sand-400;
$border-color: $sand-500;
$light-body-bg: $sand-500;
$danger: $peach-800;
$time-slot-bg: $petrol-400;

$chat-bg-color: $blue-500;
$chat-text-color: $petrol-500;

$font-family-serif: var(--font-inter), serif;
$font-family-sans-serif: var(--font-inter), sans-serif;
$font-family-base: $font-family-sans-serif;
$headings-font-family: var(--font-serif), serif;

$h1-font-weight: 600;
$h1-font-weight-lg: 600;
$h2-font-weight: 600;
$h2-font-weight-lg: 600;
$h3-font-weight: 600;
$h3-font-weight-lg: 600;
$h4-font-weight: 600;
$h4-font-weight-lg: 600;
$display1-font-weight: 600;
$display1-font-weight-lg: 600;
