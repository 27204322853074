.slider {
  &__wrapper {
    position: relative;
    width: 100%;

    .glider-contain {
      margin-bottom: 32px;
    }

    .glider-slide {
      align-content: flex-start;
    }
  }
}

.glider-next, .glider-prev {
  width: 66px;
  height: 66px;
  background-color: $light;
}


