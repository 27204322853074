.clients-card {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100% - 30px);
  margin: 10px 0;
  padding: 22px;
  background-color: $white;
  border-radius: 20px;
  &__name {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 24px;
  }
  &__info {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: 40px;
  }
}
