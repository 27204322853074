.service {
  margin-bottom: 40px;
  @include media-breakpoint-tablet() {
    width: 100%;
  }
  &__list {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: height 2s;
    @include media-breakpoint-tablet() {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 40px;
      justify-content: center;
    }
  }
  &__button {
    display: flex;
    max-height: 80px;
    height: 80px;
    align-items: center;
    border: none;
    outline: none;
    background-color: $white;
    width: 260px;
    border-radius: $border-radius-xl;
    box-shadow: 0 7px 20px rgba($button-shadow, 0.3);
    cursor: pointer;
    @include media-breakpoint-tablet() {
      width: 212px;
      margin: 10px;
    }
    &--active,
    &:hover {
      background-color: $light;
      @include media-breakpoint-tablet() {
        box-shadow: none;
      }
    }
    &:focus {
      outline: none;
      @include media-breakpoint-tablet() {
        box-shadow: none;
      }
    }

    &--select {
      border-bottom: 2px solid $light-divider;
      @include media-breakpoint-tablet() {
        display: none;
      }
    }
    &--open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      @include media-breakpoint-tablet() {
        border-radius: $border-radius-xl;
        box-shadow: none;
      }
    }
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 12px 20px 17px;
    &--border {
      border-right: 2px solid $light-divider;
    }
  }
  &__item-icon {
    margin-right: 14px;
  }
  &__item-label {
    display: block;
    line-height: 18px;
    color: $text-color;
    font-size: $font-size-sm;
    margin-bottom: 0;
    text-align: left;
    @include media-breakpoint-tablet() {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    > strong::first-letter {
      text-transform: uppercase;
    }
  }
  &__select-icon {
    height: 6px;
    width: 6px;
  }
  &__select {
    padding: 0 20px;
  }
}

.service__list--open {
  display: flex;
  .service__button {
    border-radius: 0;
    @include media-breakpoint-tablet() {
      border-radius: $border-radius-xl;
    }
    &:last-child {
      border-bottom-right-radius: $border-radius-xl;
      border-bottom-left-radius: $border-radius-xl;
      @include media-breakpoint-tablet() {
        border-radius: $border-radius-xl;
      }
    }
  }
}
