.appointments-to-pay {
  &__header {
    margin-bottom: 40px;
    .app-title {
      margin-bottom: 0;
    }
  }
  &__content {
    max-width: 321px;
    @include media-breakpoint-tablet() {
      max-width: 612px;
    }
  }
  &__card {
    padding: 40px;
    background-color: $white;
    border-radius: 36px;
  }
  &__card-header {
    padding-bottom: 30px;
    h2 {
      margin: 0;
    }
  }
  &__confirm-text {
    padding-top: 10px;
    color: $text-color-light;
  }
  &__dots {
    flex-grow: 10;
    margin: 0 auto;
    padding-bottom: 25px;
    background: radial-gradient(circle, rgba(48, 48, 48, 0.62) 2px, transparent 2px) repeat-x;
    background-size: 20px 20px;
  }

  &__checkbox-wrapper {
    margin-bottom: 30px;

    .custom-checkbox.custom-control {
      padding-left: 0;
      label:first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__appointments,
  &__total {
    display: block;
    margin-bottom: 15px;
  }

  &__cancel-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .btn-link-inline-underline {
      font-size: 20px;
    }
  }
}
