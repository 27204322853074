// mixins

@mixin media-breakpoint-big-phone() {
  @media screen and (min-width: 450px) {
    @content;
  }
}
@mixin media-breakpoint-tablet() {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin media-breakpoint-big-tablet() {
  @media screen and (min-width: 1024px) {
    @content;
  }
}
@mixin media-breakpoint-big-tablet-only() {
  @media screen and (min-width: 1024px) and (max-width: 1219.9px) {
    @content;
  }
}
@mixin media-breakpoint-laptop() {
  @media screen and (min-width: 1220px) {
    @content;
  }
}
@mixin media-breakpoint-desktop() {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

// * {
  // font-family: $font-family-base !important;
// }

body {
  overscroll-behavior-y: contain;
  margin: 0;
  background-color: $body-bg;
  line-height: 1.5;
}

img {
  max-width: 100%;
  height: auto;
}

.first-site-block {
  @media screen and (min-width: 992px){
    padding-top: 93px;
  }
  @include media-breakpoint-laptop() {
    padding-top: 159px;
  }
}

.app-container {
  min-height: 100dvh;
  ::-webkit-scrollbar {
    width: $scrollbar-width;
    height: $scrollbar-height;
  }

  ::-webkit-scrollbar-track {
    background-color: $scrollbar-track-bg;
    border-radius: $scrollbar-border-radius;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $scrollbar-handle-bg;
    border-radius: $scrollbar-border-radius;
    &:hover {
      background-color: $scrollbar-handle-hover-bg;
    }
  }

  .app-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: $mobile-header-height;
    overflow: hidden;
    @include media-breakpoint-big-tablet() {
      position: absolute;
      flex-direction: row;
      padding-top: 0;
      right: 0;
      left: $sidebar-max-width;
    }
    @include media-breakpoint-desktop() {
      left: $sidebar-max-width-desktop;
    }
    &__content-wrapper {
      width: 100%;
      min-height: calc(100vh - $mobile-header-height);
      @include media-breakpoint-big-tablet() {
        max-height: inherit;
        overflow-y: auto;
        min-height: 100vh;
      }
    }
    &__content {
      max-width: $content-max-width;
      margin: 25px auto;
      padding: $content-padding;
      @include media-breakpoint-tablet() {
        padding: $content-padding-laptop;
      }

      &--full-screen {
        max-width: none;
        padding: 0;
        margin: 0;
      }
    }

    &.no-overflow {
      overflow: initial;
      height: 100%;

      .app-container .app-wrapper__content-wrapper {
        height: 100%;
        overflow: initial;
      }

      .app-wrapper__content-wrapper, .app-container .app-wrapper__content {
        height: 100%;
      }

    }
  }
  .app-title {
    margin-bottom: 20px;
    @include media-breakpoint-tablet() {
      margin-bottom: 40px;
    }
  }
  .archive-page {
    min-height: 100vh;

    &::before {
      height: 300px;
      @include media-breakpoint-desktop() {
        height: 270px;
      }
    }
    .wave {
      top: 300px;
      // display: flex;
      width: 100vw;
      height: auto;
      @include media-breakpoint-desktop() {
        top: 270px;
      }
    }
    .first-site-block {
      padding-top: 130px;
      @include media-breakpoint-tablet() {
        padding-top: 170px;
      }
    }

    &--service {
      min-height: auto;

      &::before {
        height: 400px;
        @include media-breakpoint-desktop() {
          height: 380px;
        }
      }

      .wave {
        top: 400px;
        @include media-breakpoint-desktop() {
          top: 380px;
        }
      }
    }

  }
  .service-page {
    min-height: auto;
  }
  .detail-wrapper {
    margin-bottom: 60px;
  }

  .form-combined-checkbox {
    margin: 30px 0 0 0;
    font-size: $font-size-sm;
    line-height: 2;
  }

  .form-checkbox {
    margin: 30px 0;
    font-size: $font-size-sm;
    line-height: 2;
    + .form-submit {
      margin-top: 10px;
    }
  }
  .form-submit {
    margin: 35px 0;
  }
  .form-submit--last {
    margin-bottom: 0;
  }

  .modal {
    display: block;
    .modal-dialog {
      padding: 0;
      @include media-breakpoint-tablet() {
        padding: 0 20px;
      }
    }
  }

  .text-color {
    &-white {
      color: $white;
    }
  }

  span {
    + .button-link {
      margin-left: 10px;
    }
  }

  table {
    td.day-cell {
      display: none;
      &.today {
        display: table-cell;
        & + td,
        & + td + td {
          display: table-cell;
        }
      }
    }
  }
}


.container {
 &--service {
   @include media-breakpoint-tablet() {
     max-width: 700px;
   }

   @include media-breakpoint-big-tablet() {
     max-width: 960px;
   }

   @include media-breakpoint-laptop() {
      max-width: 1180px;
    }
  }
}


.wave {
  &--brown-bg {
    fill: $brown-bg
  }
}


.site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.gradient-bg-bordeaux {
  background: linear-gradient(180deg, #F1EBE5  -30%, rgba(255, 255, 255, 0) -55%, #F1EBE5 64%);
}

.gradient-bg-body-bg {
  background: linear-gradient(180deg, #fdf7f1  -30%, rgba(255, 255, 255, 0) -55%, #fdf7f1 64%);
}

.gradient-bg-petroleum {
  background: linear-gradient(180deg, #EEF4F4  -30%, rgba(255, 255, 255, 0) -55%, #EEF4F4 64%);
}

.gradient-sand-500 {
  background: linear-gradient(180deg, #F3F0EB  -30%, rgba(255, 255, 255, 0) -55%, #F3F0EB 64%);
}
