.react-datepicker__navigation--years {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  top: 7px;
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: relative;
  width: 9px;
  margin-bottom: 10px;
  transform: rotate(-45deg);
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  bottom: 7px;
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: relative;
  width: 9px;
  margin-bottom: 10px;
  transform: rotate(135deg);
}

.react-datepicker__year-option:first-of-type, .react-datepicker__year-option:last-of-type {
  height: 20px;
}

.react-datepicker__year-option:hover {
  background-color: white;
}