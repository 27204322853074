.feedback-header {
  margin-bottom: 40px;
  text-align: center;
  &__number {
    position: relative;
    display: inline-block;
    width: 57px;
    height: 57px;
    margin: 0 40px;
    padding: 10px 10px 8px;
    font-weight: 700;
    font-size: 22px;
    background-color: $light;
    border-radius: 100%;
    &:not(:first-of-type) {
      &::before {
        position: absolute;
        top: 50%;
        right: calc(100% + 20px);
        width: 40px;
        height: 2px;
        background-color: $light;
        content: '';
      }
    }
  }
  &__number--active {
    color: $white;
    background-color: $primary;
  }
}
