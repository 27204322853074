/* ==========================================================================
Site
========================================================================== */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grecaptcha-badge {
  visibility: hidden !important; /* stylelint-disable-line declaration-no-important */
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: $body-font-size;
  @include media-breakpoint-up(lg) {
    font-size: $body-font-size-lg;
  }
  // stylelint-disable-next-line selector-no-qualifying-type
  &.nav-open {
    @include media-breakpoint-down(md) {
      height: 100vh;
      overflow: hidden;
    }
  }

  // in body to overwrite reservanto styles
  .reservanto-widget {
    height: auto;

    .reservanto-button {
      // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
      @extend .btn;
      // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
      @extend .btn-primary;
    }
  }
}

.site {
  //max-width: 1920px;
  //margin: 0 auto;
  overflow: hidden;
  background-color: $white;
  box-shadow: 0 7px 50px rgba(211, 155, 123, 0.2);

  &__logo {
    position: relative;
    z-index: 1;
    width: 152px;
    height: 49px;
    @include media-breakpoint-up(lg) {
      width: 194px;
      height: 63px;
    }

    path {
      transition: fill 0.2s;
    }
  }

  &__content {
    transition: filter 0.2s;
  }
}

/* ==========================================================================
Typography
========================================================================== */
h1,
.h1 {
  font-weight: $h1-font-weight;
  font-size: $h1-font-size;
  line-height: $h1-line-height;
  @include media-breakpoint-up(lg) {
    font-weight: $h1-font-weight-lg;
    font-size: $h1-font-size-lg;
    line-height: $h1-line-height-lg;
  }
}

h2,
.h2 {
  font-weight: $h2-font-weight;
  font-size: $h2-font-size;
  line-height: $h2-line-height;
  @include media-breakpoint-up(lg) {
    font-weight: $h2-font-weight-lg;
    font-size: $h2-font-size-lg;
    line-height: $h2-line-height-lg;
  }
}

h3,
.h3 {
  font-weight: $h3-font-weight;
  font-size: $h3-font-size;
  line-height: $h3-line-height;
  @include media-breakpoint-up(lg) {
    font-weight: $h3-font-weight-lg;
    font-size: $h3-font-size-lg;
    line-height: $h3-line-height-lg;
  }
}

h4,
.h4 {
  font-weight: $h4-font-weight;
  font-size: $h4-font-size;
  line-height: $h4-line-height;
  @include media-breakpoint-up(lg) {
    font-weight: $h4-font-weight-lg;
    font-size: $h4-font-size-lg;
    line-height: $h4-line-height-lg;
  }
}

.display-1 {
  font-weight: $display1-font-weight;
  font-size: $display1-font-size;
  line-height: $display1-line-height;
  @include media-breakpoint-up(lg) {
    font-weight: $display1-font-weight-lg;
    font-size: $display1-font-size-lg;
    line-height: $display1-line-height-lg;
  }
}

label:not(.custom-control-label) {
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  text-transform: uppercase;
}

.first-site-block {
  padding-top: 93px;
  @include media-breakpoint-up(lg) {
    padding-top: 159px;
  }
}

/* ==========================================================================
Buttons
========================================================================== */
.btn {
  font-weight: $btn-font-weight;
  text-transform: uppercase;
}

/* ==========================================================================
Divider
========================================================================== */
.divider {
  display: block;
  width: 100%;
  height: 2px;
  background: url('/images/svg/styleElements/dots2-horizontal.svg') center center repeat-x;
  border: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}
