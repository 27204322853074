/* ==========================================================================
Video room : Global styles
========================================================================== */
.video-room {
  &--stop {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    @include media-breakpoint-tablet() {
      padding: 100px 0 40px 0;
    }
  }
  &__back-button {
    padding: 25px 15px;
    @include media-breakpoint-tablet() {
      padding: 40px 30px 50px 40px;
    }
  }
}

/* ==========================================================================
Call : Styles
========================================================================== */
.call {
  height: 100vh;
  overflow: hidden;

  /* ==========================================================================
  Call : Chat visible
========================================================================== */
  &--with-chat {
    @include media-breakpoint-laptop() {
      display: flex;
      .call__local-participant.call__local-participant--full-screen {
        .call__controls {
          position: absolute;
        }
      }
    }
  }

  /* ==========================================================================
  Call : Controls
  ========================================================================== */
  &__controls-button-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__controls {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    text-align: center;
    &-hide {
      z-index: -1;
    }
    &-panel {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      border-radius: 14px 14px 0 0;
      background-color: $light-body-bg;
      height: $call-controls-mobile-height;
      @include media-breakpoint-big-tablet() {
        justify-content: space-between;
      }
      @include media-breakpoint-big-tablet() {
        border-radius: 0;
        padding: 10px;
      }
      &-icons {
        display: flex;
        @include media-breakpoint-big-tablet() {
          flex: 1;
          justify-content: center;
        }
      }
      &-right {
        display: none;
        @include media-breakpoint-big-tablet() {
          display: flex;
          flex: 1;
          justify-content: flex-end;
        }
      }
      &-help {
        text-transform: none;
        padding-right: 33px;
      }
      &-connection {
        display: flex;
        align-items: center;
        margin-left: 8px;
        margin-right: 8px;
        text-transform: none;
        @include media-breakpoint-big-tablet() {
          flex: 1;
        }
      }
      &-connect {
        display: none;
        @include media-breakpoint-big-tablet() {
          flex: 1;
          margin-left: 6px;
          display: block;
          font-size: 14px;
          color: $secondary;
        }
      }
      &-icon {
        width: 16px;
        height: 16px;
        color: $secondary;
      }
      &-hide {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        padding: 8px;
        text-transform: none;
        font-size: 12px;
        &--hidden {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 6px;
          padding: 10px;
          text-transform: none;
          font-size: 12px;
          position: fixed;
          bottom: 12px;
          right: 12px;
          padding: 10px;
          z-index: 100000;
        }
        &-eye {
          padding-left: 3px;
        }
      }
    }

    &-button {
      position: relative;
      margin: 0 8px;
      padding: 0;
      border: transparent;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 43px;
      height: 43px;
      @include media-breakpoint-laptop() {
        width: 50px;
        height: 50px;
        padding: 5px 4px;
        justify-content: space-around;
      }
      &--track {
        position: relative;
      }
      &--audio-disable {
        .track-level-call__wrapper {
          canvas {
            display: none;
          }
        }
      }
    }

    .btn-danger {
      &:hover {
        svg {
          color: $white;
        }
      }
    }

    .btn-danger,
    .btn-brownies,
    .btn-light {
      outline: none;
      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &__control-text {
    display: none;
    text-transform: none;
    @include media-breakpoint-laptop() {
      display: block;
      font-size: 10px;
      font-weight: bold;
      color: $text-color-light;
      line-height: 1;
    }
    &--microphone {
      color: $primary;
      z-index: 5;
    }
    &--hang {
      color: $white;
    }
  }

  &__message-notification-wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -180%);
  }

  &__messages-count {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $brown;
    height: 19px;
    width: 19px;
    color: $white;
    border-radius: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    transform: translate(-25%, 25%);
  }

  &__message-notification {
    position: relative;
    color: $white;
    padding: 10px 0;
    width: 160px;
    background-color: $primary;
    font-weight: bold;
    font-size: 12px;
    text-transform: lowercase;
    display: block;
    border-radius: $border-radius;
    &:first-letter {
      text-transform: uppercase;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translate(-50%, -25%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 22px solid $primary;
      clear: both;
    }
  }

  /* ==========================================================================
Call : Top controls
========================================================================== */
  &__top-controls {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 16px;
    right: 30px;
    z-index: 5;
    border-radius: 8px;
    max-height: $call-top-controls-max-height;
  }

  &__top-controls {
    &-item {
      color: $text-color;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      padding: 8px 12px;
      border-radius: 8px;
      background-color: $body-bg;

      &--fullscreen {
        display: none;
        cursor: pointer;
        margin-right: 12px;
      }
      .call__fullscreen-icon {
        min-width: 28px;
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
      @include media-breakpoint-tablet() {
        &--fullscreen {
          display: block;
        }
      }
    }
  }

  /* ==========================================================================
  Call : Indicators
  ========================================================================== */

  &__disabled-icon--video {
    width: 50px;
    height: 50px;
  }

  &__disabled-icon--phone {
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    color: $danger;
  }

  &__disabled-icon--audio-mute {
    width: 25px;
    height: 25px;
    margin-left: 4px;
    color: $danger;
  }

  /* ==========================================================================
  Call : Feedback
  ========================================================================== */
  &__feedback {
    margin-top: 150px;
    padding-top: 40px;
    border-top: 2px solid rgba($light-brown, 0.1);
  }

  /* ==========================================================================
  Call : Room
  ========================================================================== */
  &__room {
    position: relative;
    width: 100%;
    height: 100%;
    background: $call-participant-bg;

    @media (orientation: landscape) {
      padding-top: 0;
    }
  }

  /* ==========================================================================
  Call : Video - Basic styles
  ========================================================================== */

  &__participant {
    position: relative;

    &-name {
      display: inline-block;
      padding: 2px 8px;
      overflow: hidden;
      color: $white;
      font-weight: 700;
      font-size: $font-size-sm;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: rgba($black, 0.6);
      border-radius: $border-radius-sm;
    }

    &-identity {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 2;
      display: none;

      @include media-breakpoint-tablet() {
        display: flex;
      }
    }

    &--video-paused {
      background: $call-room-bg;
    }
  }

  &__video {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 200px;
    color: $white;

    &--paused {
      margin-bottom: $call-controls-height;
    }
  }

  /* ==========================================================================
  Call : Video - Participant
  ========================================================================== */
  &__local-participant,
  &__remote-participants {
    width: 100%;
    height: 100%;
    .call__participant {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .call__video {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }
  }

  /* ==========================================================================
  Call : Video - Modifiers (during call, more participants, etc.)
  ========================================================================== */
  &__local-participant--in-corner {
    width: auto;
    height: auto;
    .call__participant {
      position: absolute;
      top: 70px;
      right: 30px;
      z-index: 5;
      max-width: 100px;
      height: auto;
      overflow: hidden;
      border-radius: $border-radius;
      transform: none;
      &--with-top-controls {
        top: 70px;
      }
      @include media-breakpoint-desktop() {
        top: calc(#{$call-top-controls-max-height} + 20px);
      }
      &--video-paused {
        padding: 20px;
      }
      &-identity {
        display: none;
      }
    }

    .call__video {
      position: relative;
      top: initial;
      left: initial;
      width: 100%;
      // transform: none;
      min-height: auto;
      &--paused {
        margin: 0;
        padding: 10px;
        .icon--native {
          width: 32px;
          height: 32px;
          margin-bottom: 10px;
        }

        h3 {
          display: none;
        }
      }
    }
  }

  &__local-participant--full-screen,
  &__remote-participants {
    .call__video {
      padding-bottom: $call-controls-mobile-height;
      &--full {
        padding-bottom: 0;
      }
    }
    &--full-screen {
      .call__participant {
        &--video-paused {
          background: $call-participant-bg;
        }
      }
    }
  }

  &__table {
    margin-bottom: 42px;
    overflow-x: auto;

    table {
      overflow: hidden;
      background-color: $white;
      border: 1px solid $light;
      border-radius: 20px;

      span {
        font-size: 16px;
        line-height: 24px;
      }

      th {
        padding: 18px 23px 15px;
        border: 1px solid $light;
      }

      td {
        padding: 13px 44px 11px 19px;
        border: 1px solid $light;

        &:not(:first-child) {
          padding: 13px 0 11px;
          text-align: center;
        }
      }
    }
  }
}

/* ==========================================================================
Call: StopVideoContainer
========================================================================== */

.call-stop {
  &__appointments-to-pay-call {
    display: flex;
    justify-content: center;
  }
  &__label {
    display: block;
    padding-bottom: 10px;
  }
  &__back-to-call {
    display: flex;
    justify-content: center;
  }
  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__feedback-wrapper {
    margin: 20px 0 0 0;
  }

  @include media-breakpoint-tablet() {
    &__buttons-wrapper {
      flex-direction: row;
      justify-content: center;
    }
    &__feedback-wrapper {
      margin: 0 0 0 20px;
    }
  }
}
