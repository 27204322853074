.therapist {
  &__new-client {
    display: inline-block;
    padding: 0 10px;
    margin: 0 0 10px 25px;
    color: $white;
    font-size: 12px;
    text-transform: uppercase;
    background-color: $secondary;
    border-radius: 6px;
    @include media-breakpoint-tablet() {
      padding: 1px 15px;
      font-size: $font-size-sm;
    }
  }
}

.therapists-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__item {
    margin-bottom: 30px;
    @include media-breakpoint-tablet() {
      margin-bottom: 0;
    }
    @include media-breakpoint-desktop() {
      margin-bottom: 0;
    }
    &:last-of-type {
      @include media-breakpoint-tablet() {
        margin-bottom: 30px;
      }
      @include media-breakpoint-desktop() {
        margin-bottom: 0;
      }
    }
  }
  &__title {
    min-width: 100%;
    margin-bottom: 20px;
    text-align: center;
    h2 {
      font-weight: 400; // exception, some parts are light, set font-weight using <strong>
    }
  }
  &__limited-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    text-align: center;
  }
  &__limited-title {
    max-width: 600px;
  }
  &__row {
    min-width: 100%;
  }

  &__row--diagnosis {
    padding-bottom: 40px;
  }

  // exceptions to keep WP classes
  .therapist-card__button {
    height: 100%;
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
    &:focus {
      outline: none;
    }
  }

  &__diagnosis-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    @include media-breakpoint-tablet() {
      flex-direction: row;
      margin-bottom: 55px;
    }
  }
  &__show-therapists {
    margin-bottom: 20px;
    @include media-breakpoint-tablet() {
      margin: 0 20px 0 0;
    }
  }
}

.therapist__info-wrapper--horizontal {
  margin-bottom: 40px;

  &.therapist__info-wrapper--no-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }

  @include media-breakpoint-tablet {
    flex-direction: row;
    align-items: center;
  }
  .therapist {
    &__col-wrapper {
      display: flex;
      flex: 1;
      align-items: center;
      width: 100%;
      padding: 0 20px;
      @include media-breakpoint-tablet {
        flex-flow: row nowrap;
        padding: 0;
      }
    }
    &__info-content {
      @include media-breakpoint-tablet() {
        margin-top: 0;
        margin-left: 25px;
        text-align: left;
      }
    }
    &__info-col--last {
      padding-left: 20px;
      text-align: left;
      @include media-breakpoint-laptop {
        padding-left: 60px;
      }
    }
    &__info-col--image-wrapper {
      width: 99px;
      height: 99px;
    }
    &__info-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
    &__info-name {
      display: block;
      margin-bottom: 0;
    }
    &__info-proves {
      display: flex;
      flex: 1;
      margin: 20px 0;
      @include media-breakpoint-tablet {
        margin: 0;
      }
    }
    &__info-proves-item {
      margin-bottom: 0;
      &:not(:last-of-type) {
        padding-right: 40px;
      }
      @include media-breakpoint-laptop {
        padding-right: 60px;
      }
    }
  }
}

.therapist-filter {
  &__switch-to-czech {
    text-align: center;
    padding: 60px 0 80px 0;
    h2 {
      max-width: 560px;
    }
  }
}
