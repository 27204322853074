// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  @include border-radius($badge-border-radius);
  @include transition($badge-transition);
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-weight: $badge-font-weight;
  font-size: 11px;
  line-height: 1;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  vertical-align: baseline;

  &-lg {
    padding: 10px 15px;
    font-size: 14px;
  }

  &-sm {
    padding: $badge-sm-padding-y $badge-sm-padding-x;
  }

  @at-root a#{&} {
    @include hover-focus() {
      text-decoration: none;
    }
  }
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $badge-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

.badge-dawn-pink {
  color: $text-color-light;
}
