.strapi-page {
  &__content {

    h1, h2, h3, h4, h5 {
      margin: 23px 0 15px;
      font-weight: 600;

      @include media-breakpoint-big-tablet() {
        margin: 52px 0 25px;
      }
    }

    h1, h2 {
      margin-bottom: 50px;
    }


   .rich-content {
     p {
       font-size: $font-size-md;
       line-height: 30px;
     }
   }

    li {
      padding: 12px 0;
    }

    img {
      margin-bottom: 46px;
    }

    ol {
      list-style: none;
      counter-reset: li-counter;
      margin: 30px 0 30px;
      padding: 0;

      li {
        font-weight: 700;
        padding: 0;
        counter-increment: li-counter;
        margin: 24px 0;

        &:before {
          content: counter(li-counter);
          color: $secondary;
          font-size: 14px;
          font-weight: bold;
          width: 26px;
          height: 26px;
          border: 2px solid $secondary;
          border-radius: 50px;
          display: inline-block;
          text-align: center;
          padding: 1px;
          margin-right: 20px;
        }
      }
    }
  }
}
