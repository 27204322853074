// .form-control {
//   &.wpcf7-not-valid {
//     // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
//     @extend .is-invalid;
//   }
// }

// .wpcf7 form .wpcf7-response-output {
//   // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
//   @extend .alert;
//   // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
//   @extend .alert-warning;
//   margin: 20px 0;
//   @include media-breakpoint-up(lg) {
//     margin: 40px 0;
//   }
// }

// .wpcf7-form.failed {
//   .wpcf7-response-output {
//     @extend .alert-danger;
//   }
// }

label:not(.custom-control-label) {
  width: 100%;
}

// .wpcf7-not-valid-tip {
//   text-transform: none;
// }

// .wpcf7 .sent .wpcf7-response-output {
//   // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
//   @extend .alert-success;
// }

/*
custom checkbox
========================================================================== */
.custom-checkbox {
  label {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
  }

  span.wpcf7-list-item {
    margin-left: 0;
  }
  input[type='checkbox'] {
    display: none;
    & + label,
    & + .wpcf7-list-item-label {
      position: relative;
      display: inline-block;
      padding-left: 30px;
      cursor: pointer;
      &:hover {
        &::before {
          border: 2px solid $primary;
        }
      }
      &::before,
      &::after {
        position: absolute;
        top: 4px;
        left: 0;
        //transform: translateY(-50%);
        width: 20px;
        height: 20px;
        transition: all 0.2s;
        content: '';
      }
      &::before {
        box-sizing: border-box;
        border: 2px solid $brown;
        border-radius: 4px;
      }
      &::after {
        top: 14px;
        background-image: url('/images/svg/styleElements/cf7-check.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 13px 8px;
        transform: translateY(-50%) scale(0);
        opacity: 0;
      }
    }

    &:checked {
      & + label,
      & + .wpcf7-list-item-label {
        &::before {
          background-color: $primary;
          border-color: $primary;
        }
        &::after {
          transform: translateY(-50%) scale(1);
          opacity: 1;
        }
      }
    }

    &:disabled {
      & + label,
      & + .wpcf7-list-item-label {
        color: #6b7c93;
        &::before {
          background-color: #cedceb;
          border-color: #cedceb;
          box-shadow: none;
        }
      }

      &:checked {
        & + label,
        & + .wpcf7-list-item-label {
          &::after {
            color: #999;
          }
        }
      }
    }
  }
}
