//
// Base styles
//

.alert {
  @include border-radius($alert-border-radius);
  position: relative;
  margin-bottom: $alert-margin-bottom;
  padding: $alert-padding-y $alert-padding-x;
  border: $alert-border-width solid transparent;
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $alert-colors {
  .alert-#{$color} {
    @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-color-level));
  }
}
