.radio-tabs {
  display: flex;
  &__item {
    display: flex;
    overflow: hidden;
    background-color: transparent;
    cursor: pointer;

    &:first-child {
      label {
        border-right: 0;
        border-top-left-radius: $radio-border-radius;
        border-bottom-left-radius: $radio-border-radius;
      }
    }
    &:last-child {
      label {
        border-left: 0;
        border-top-right-radius: $radio-border-radius;
        border-bottom-right-radius: $radio-border-radius;
      }
    }
  }
  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &__label {
    margin-bottom: 1px;
    padding: 8px 30px;
    background-color: $white;
    border: 2px solid $light;
    font-weight: 600 !important;
    cursor: pointer;
    &:hover {
      background-color: $light;
    }
    span {
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }
    &--checked {
      color: $white;
      background-color: $primary;
      &:hover {
        background-color: $primary;
      }
    }
  }
}

.radio {
  display: flex;
  &__item {
    display: flex;
    overflow: hidden;
    background-color: transparent;
  }

  /* basic settings override */
  input {
    -webkit-appearance: none;
  }

  label {
    text-transform: none;
    font-weight: 600;
    font-size: $font-size-md;
    cursor: pointer;
  }

  &__label {
    display: flex;
    border-radius: $border-radius-lg;
    border: 2px solid $light-grey;
    padding: 16px 24px;
    margin-bottom: 12px;

    &--checked {
      background: $body-bg;
      border-color: $primary;

      svg {
        color: $primary;
      }
    }

    .label-wrapper {
      &__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__item {
    &--plain {
      label {
        border: none;
        background-color: $white;
      }

      .label-wrapper {
        &__content {
          justify-content: normal;

          svg {
            margin-right: 17px;
          }
        }
      }

      .radio__label--checked {
        background-color: $primary;
        color: $white;

        svg {
          color: currentColor;
        }
      }
    }

    &--submit {
      overflow: unset;
      label {
        border: none;
        background-color: $white;
        border-radius: 100px;
        padding: 15px 30px;
        display: flex;
        align-items: center;
        box-shadow: 0px 7px 30px rgba(211, 155, 123, 0.3);
        margin-bottom: 20px;
        font-size: 16px;

        @include media-breakpoint-tablet() {
          padding: 24px 40px;
        }
      }

      .label-wrapper {
        &__content {
          justify-content: normal;

          svg {
            display: none;
          }
        }
      }

      .radio__label--checked {
        background-color: $primary;
        color: $white;

        svg {
          color: currentColor;
        }
      }
    }
  }
}
