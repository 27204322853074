/* ==========================================================================
Single - Therapist
========================================================================== */
.single-therapist {
  .site {
    background-color: $sand;
  }
}

.therapist {
  // ======================= Headings
  &__heading {
    display: block;
    margin-top: 10px;
    margin-bottom: 24px;
  }

  // ======================= Overview structure
  &__overview {
    margin-top: $site-header-height;
    @include media-breakpoint-up(lg) {
      margin-top: $site-header-lg-height;
    }
    &-wrapper {
      display: flex;
      flex-flow: column nowrap;
      padding: 25px 0;
      @include media-breakpoint-up(md) {
        flex-flow: row nowrap;
        padding: 40px 0;
      }
      @include media-breakpoint-up(lg) {
        padding: 50px 0;
        padding-bottom: 60px;
      }
    }
  }

  // ======================= Info
  &__info {
    @include media-breakpoint-up(md) {
      max-width: 270px;
    }
    &-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding: 21px 25px;
      text-align: center;
      background-color: $dawn-pink;
      border-radius: 12px;
    }
    span {
      display: block;
    }
    &-img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 154px;
      margin-bottom: 22px;
      overflow: hidden;
      background-color: $white;
      border-radius: 6px;
    }
    &-img {
      display: block;
      border-radius: 6px;
    }
    &-name {
      margin-bottom: 10px;
      font-weight: 800;
      font-size: 22px;
      line-height: 20px;
    }
    &-price {
      margin-bottom: 17px;
      color: $secondary;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
    }
    &-hours {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    &-proves {
      margin-top: 40px;
      color: $secondary;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: left;
      list-style-type: none;
      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        margin-bottom: 25px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &-icon {
        display: block;
        width: 21px;
        height: 21px;
        margin-right: 15px;
      }
      .list-icon__icon {
        width: 21px;
        height: 21px;
      }
    }
  }

  // ======================= Why
  &__why {
    margin-bottom: 40px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 60px;
    }
    &-item {
      margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 0;
      }
      &-wrapper {
        display: flex;
        flex-flow: row nowrap;
      }
      &-icon {
        width: 36px;
        height: 36px;
        color: $primary;
      }
      &-icon-wrapper {
        width: 50px;
      }
      &-text {
        flex: 1;
        color: $secondary;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  // ======================= Video
  &__video-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 56.25%;
    overflow: hidden;
    border-radius: 12px;
    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }
  }

  // ======================= Video (youtube)
  &__video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  // ======================= Motto quote
  &__motto {
    margin-bottom: 40px;
    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }
    &-quote {
      color: $secondary;
      font-size: 18px;
      line-height: 28px;
      @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  // ======================= Education
  &__education {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 28px;
    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  // ======================= Calendar
  &__calendar {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 12px;
    @include media-breakpoint-up(md) {
      margin-left: 34px;
      background: $white url('/images/therapist-calendar.jpg') center center no-repeat;
      background-size: contain;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      display: none;
      width: 100%;
      height: 100%;
      background-color: $body-bg;
      border-radius: 12px;
      opacity: 0.6;
      content: '';
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
    &-buttons {
      position: relative;
      z-index: 2;
      display: flex;
      flex-flow: column nowrap;
      @include media-breakpoint-up(lg) {
        flex-flow: row wrap;
      }
    }
    &-reservation-title {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
    }
    .btn {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
    .reservanto-widget {
      position: relative;
      z-index: 2;
      margin-bottom: 20px;
      @include media-breakpoint-up(lg) {
        margin-right: 30px;
        margin-bottom: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
          margin-right: 0;
        }
      }
    }
  }

  // ======================= Reviews
  &__reviews {
    margin-bottom: 30px;
    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }
  }

  // ======================= Specializations
  &__specialization {
    margin-bottom: 30px;
    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }
    &-primary {
      margin-bottom: 30px;
    }
    &-item {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  // ======================= BIO
  &__bio {
    margin-bottom: 80px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 150px;
    }
    &-wrapper {
      display: flex;
      flex-flow: column nowrap;
      @include media-breakpoint-up(lg) {
        flex-flow: row nowrap;
      }
    }
    &-box {
      padding: 37px 25px;
      background-color: $white;
      border-radius: 12px;
      @include media-breakpoint-up(md) {
        padding: 80px 65px;
      }
    }
    &-right {
      @include media-breakpoint-up(lg) {
        flex-basis: 46%;
        max-width: 46%;
      }
    }
    &-left {
      @include media-breakpoint-up(md) {
        padding-right: 80px;
      }
      @include media-breakpoint-up(lg) {
        flex-basis: 54%;
        max-width: 54%;
      }
    }
  }
}
