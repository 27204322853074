.diagnosis {
  /* Diagnosis HP screen */
  &__benefits-item {
    margin-bottom: 25px;
    text-align: center;
  }
  &__title {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    @include media-breakpoint-laptop {
      margin-bottom: 59px;
    }
    p {
      margin-bottom: 0;
    }
  }
  &__benefits-item-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 53px;
    margin: 0 auto;
    background-color: $body-bg;
    border-radius: 50%;
  }
  &__benefits-item-title {
    margin-top: 22px;
    color: $text-color-light;
    font-size: $font-size-sm;
    line-height: 16px;
    p {
      margin-bottom: 0;
    }
  }

  &__button {
    margin-top: 20px;
    margin-bottom: 21px;
    @include media-breakpoint-laptop {
      margin-top: 60px;
    }
  }
  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__icon-text {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: $font-size-sm;
    @include media-breakpoint-laptop {
      margin-bottom: 40px;
    }
    p,
    span {
      margin-bottom: 0;
      margin-left: 15px;
    }
  }

  /* Diagnosis bottom navbar part */
  &__footer {
    align-items: center;
  }
  &__prev-question {
    text-align: center;


    .btn {
      padding-left: 0;
      font-size: 12px;
      line-height: 34px;
    }
    .icon {
      margin-right: 15px;
      transform: rotate(-90deg);
      transition: all 0.4s;
    }
    &:hover .icon {
      transform: rotate(-90deg) translateY(-10px);
    }

    @include media-breakpoint-tablet() {
      text-align: left;


    }
  }
  &__questions {
    font-size: 14px;
    text-align: center;
    @include media-breakpoint-tablet() {
      text-align: right;
    }
    @include media-breakpoint-laptop() {
      text-align: center;
    }
    p {
      margin: 0;
    }
  }

  /* Diagnosis test title and subtitle */
  &__test {
    h2,
    .lead {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
    h2 {
      margin-bottom: 30px;
      color: $secondary;
      font-weight: normal;
    }
    .lead {
      margin-bottom: 10px;
      color: $text-color-light;
    }
    .subtitle {
      color: $text-color-light;
      font-weight: 700;
    }
    .checkbox-list {
      .custom-control-label {
        display: flex;
        span {
          display: block;
        }
      }
    }
    .custom-control {
      display: block;
    }

    .fixed-button {
      @media screen and (max-width: 768px) {
        position: fixed;
        bottom: 15px;
        right: 0;
        z-index: 15;
        width: auto;

        input {
          padding: 12px 26px;
        }
      }
    }
  }
}
