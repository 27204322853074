/* ==========================================================================
Block: Signpost
========================================================================== */
.signpost {
  position: relative;
  padding: 35px 0;
  outline: none;
  @include media-breakpoint-up(md) {
    padding: 55px 0;
  }
  // ======================= Title
  &__title-wrapper {
    max-width: 560px;
    margin: 0 auto;
    padding: 0 25px;
    text-align: center;
  }
  &__title {
    margin-bottom: 55px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 85px;
    }
  }
  &__subtitle {
    display: block;
    margin-bottom: 10px;
    color: $primary;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
    }
  }

  // ======================= Links
  &__links {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    @include media-breakpoint-up(md) {
      flex-flow: row nowrap;
      margin: 0 -20px;
      text-align: left;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 -45px;
    }
  }

  // ======================= Link
  &__link {
    max-width: 450px;
    margin-bottom: 60px;
    padding: 0 30px;
    @include media-breakpoint-up(md) {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 0;
      padding: 0 20px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 45px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &-icon-wrapper {
      width: 82px;
      margin-right: auto;
      margin-bottom: 30px;
      margin-left: auto;
      color: $primary;
      @include media-breakpoint-up(md) {
        width: 50px;
        margin-right: 30px;
      }
      @include media-breakpoint-up(lg) {
        width: 82px;
        margin-right: 50px;
      }
      img {
        width: 100%;
        max-width: 82px;
        @include media-breakpoint-up(md) {
          max-width: 50px;
        }
        @include media-breakpoint-up(lg) {
          max-width: 82px;
        }
      }
    }
    &-content {
      flex: 1;
    }
    &-icon {
      width: 82px;
      height: 82px;
      fill: $primary;
    }
    &-text {
      color: $secondary;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
    }
    &-more {
      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }
    }
  }
}
