html {
  --mobile-header-height: 82px;
  --mobile-chat-header-height: 64px;

  // we need to extract these here to use in tailwind
  --color-primary: #{$primary};
  --color-secondary: #{$secondary};
  --color-text-color: #{$text-color};
  --color-text-color-light: #{$text-color-light};
  --color-body-bg: #{$body-bg};
  --color-light: #{$light};
  --color-alternative-bg: #{$alternative-bg};
  --color-light-brown-trans: #{$light-brown-trans};
  --color-aqua: #{$aqua};
  --color-light-grey: #{$light-grey};
  --color-light-body-bg: #{$light-body-bg};
}

:root * {
  --toastify-color-success: #{$toast-success};
  --toastify-color-warning: #{$toast-warning};
  --toastify-color-error: #{$toast-error};
  --toastify-color-info: #{$primary};
}

// colors
// HEDEPY COLOR SELECTION
$white: #ffffff;
$peach: #ff8f67;
$petroleum: #2f747a;
$petroleum-light: #adcccc;
$bordeaux: #490811;
$bordeaux-light: #82555c;
$bordeaux-lighter: #a98687;
$bordeaux-background: #f1ebe5;

// PREVIOUSLY USED COLORS
$brown-bg: #322426;
$light-divider: #eee7e0;
$button-shadow: #d39b7b;
$black: #000000;
$brown: #490811;
$light-brown: #82555c;
$brownies: #846d71;
$error: #fa3c53;
$success: #30747a;
$danger: #fa3c53;
$salmon: #ec3553;

// fonts
$font-size-sm: 14px;
$font-size-base: 16px;
$font-size-md: 18px;
$font-size-lg: 26px;
$line-height-md: 19px;

// misc
$border-radius-sm: 4px;
$border-radius: 8px;
$border-radius-lg: 12px;
$border-radius-xl: 20px;
$border-radius-normal: 100px;

// scrollbar
$scrollbar-width: 6px;
$scrollbar-height: $scrollbar-width;
$scrollbar-track-bg: $light;
$scrollbar-border-radius: 42px;
$scrollbar-handle-bg: $primary;
$scrollbar-border-radius: $scrollbar-border-radius;
$scrollbar-handle-hover-bg: $brown;
$scrollbar-offset: 14px;

// navigaton item
$navigation-item-border-width: 2px;
$navigation-item-border-color: $border-color;
$navigation-item-hover-bg: $aqua;
$navigation-item-active-color: $secondary;
$navigation-item-active-bg: $navigation-item-hover-bg;
$navigation-item-border-radius: 0;
$navigation-item-icon-offset: 25px;
$navigation-item-font-size: $font-size-sm;

// session box
$session-box-offset: 20px;
$session-box-margin-y: 0;
$session-box-margin-x: 20px;
$session-box-margin: $session-box-offset $session-box-margin-x $session-box-margin-y;
$session-box-padding-y: 15px;
$session-box-padding-x: 25px;
$session-box-padding: $session-box-padding-y $session-box-padding-x;
$session-box-border-radius: 14px;
$session-box-font-size: $font-size-sm;

// content
$content-max-width: 1120px;
$content-padding-y: 0;
$content-padding-x: 15px;
$content-padding: $content-padding-y $content-padding-x;
$content-padding-y-laptop: 30px;
$content-padding-x-laptop: 20px;
$content-padding-laptop: $content-padding-y-laptop $content-padding-x-laptop;

// mobile-header
$mobile-header-max-width: $content-max-width;
$mobile-header-height: var(--mobile-header-height);
$mobile-header-padding-y: 17px;
$mobile-header-padding-x: $content-padding-x;
$mobile-header-padding: $mobile-header-padding-y $mobile-header-padding-x;
$mobile-header-shadow: 0 0 5px 2px rgba($brown, 0.2);

// sidebar
$sidebar-max-width: 240px;
$sidebar-max-width-desktop: 290px;
$sidebar-padding-y: 40px;
$sidebar-padding-x: 40px;
$sidebar-padding: $sidebar-padding-y $sidebar-padding-x;
$sidebar-bg: $white;
$sidebar-mobile-shadow: $mobile-header-shadow;
$sidebar-logo-height: 130px;
$sidebar-navigation-offset: 10px;
$sidebar-footer-height: 119px;
$sidebar-footer-offset: $sidebar-padding-y;
$sidebar-content-height: calc(100% - #{$sidebar-logo-height + $sidebar-footer-height});
$sidebar-content-mobile-height: calc(100% + #{$sidebar-footer-height});
$sidebar-content-mobile-offset: $mobile-header-height;

// card
$card-padding-y: 50px;
$card-padding-x: 65px;
$card-padding: $card-padding-y $card-padding-x;
$card-border-radius: 12px;
$card-bg: $white;

// radio
$radio-border-radius: 40px;

// signup
$signup-header-height: 143px;
$signup-header-offset: 10px;
$signup-header-padding-y: 20px;
$signup-header-padding-x: 0;
$signup-header-padding: $signup-header-padding-y $signup-header-padding-x;
$signup-logo-width: 194px;

// toaster
$toaster-border-radius: 4px;
$toaster-line-dark: $brown;
$toaster-line-bg-dark: $light-brown;

// call
$call-room-bg: #202124;
$call-top-controls-max-height: 70px;
$call-controls-mobile-height: 60px;
$call-controls-height: 75px;
$call-controls-btn-bg: #323232;
$call-participant-bg: linear-gradient(180deg, #0a0a0b 0%, #202124 19.25%, #202124 51.04%, #202124 80.96%, #0a0a0b 100%);
