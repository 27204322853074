.time-filter {
  .modal {
    &-body {
      padding: 0;
    }
    &-header {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      width: 100px;
      height: 100px;
    }
    &-content {
      overflow: hidden;
    }
  }

  &__item {
    .custom-control {
      margin-bottom: 5px;
      padding-left: 0;
      .custom-control-label {
        font-size: 13px;
        @include media-breakpoint-tablet() {
          font-size: $font-size-base;
        }
        &::before {
          top: 1px;
        }
        &::after {
          top: 12px;
        }
      }
    }
    .dots {
      flex-grow: 10;
      margin-top: 5px;
      margin-bottom: 10px;
      padding-bottom: 35px;
      background: radial-gradient(circle, rgba(48, 48, 48, 0.62) 2px, transparent 2px) repeat-x;
      background-size: 30px 30px;
    }
    &:nth-of-type(1) {
      position: relative;
      background-color: $body-bg;
      img {
        position: absolute;
        bottom: -27px;
        width: 100%;
      }
      .item-content {
        padding: 20px 20px 10px;
      }
    }
    &:nth-of-type(2) {
      .item-content {
        padding: 50px 20px 10px;
      }
    }
    &:nth-of-type(3) {
      border-top: 3px solid $body-bg;
      .item-content {
        padding: 20px 20px;
        text-align: right;
      }
    }
  }
}
