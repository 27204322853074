/* ==========================================================================
Archive
========================================================================== */
.archive-page {
  position: relative;
  min-height: 500px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 800px;
    background-color: $body-bg;
    content: '';
    @include media-breakpoint-up(lg) {
      height: 920px;
    }
  }
  .wave {
    top: 800px;
    z-index: 0;
    @include media-breakpoint-up(lg) {
      top: 920px;
    }
  }
  &__wrapper {
    position: relative;
  }
  // &__filter {
  //   margin: 20px 0 45px;
  //   text-align: right;
  //   @include media-breakpoint-up(lg) {
  //     display: flex;
  //     justify-content: flex-end;
  //   }
  // }
  // &__pagination {
  //   margin: 0;
  //   padding: 20px 0 100px;
  //   text-align: right;
  //   list-style-type: none;
  //   li {
  //     display: inline-block;
  //   }
  // }
  // &__pagination-button {
  //   width: 34px;
  //   height: 34px;
  //   margin: 5px;
  //   color: $brown;
  //   font-weight: $font-weight-bold;
  //   text-align: center;
  //   background-color: $light;
  //   border: 0;
  //   border-radius: 100%;
  //   transition: background-color 0.2s;
  //   &:focus {
  //     outline: none;
  //   }
  //   &:hover {
  //     background-color: darken($light, 7.5%);
  //   }
  // }
  // &__pagination-item--first {
  //   &::after {
  //     margin: 0 10px;
  //     letter-spacing: 2px;
  //     content: '...';
  //   }
  // }
  // &__pagination-item--last {
  //   &::before {
  //     margin: 0 10px;
  //     letter-spacing: 2px;
  //     content: '...';
  //   }
  // }
  // &__pagination-button--active {
  //   color: $white;
  //   background-color: $primary;
  //   &:not(:disabled),
  //   &:not([disabled]) {
  //     cursor: default;
  //   }
  //   &:hover {
  //     background-color: $primary;
  //   }
  // }
}
