.client {
  &__modal-text-wrapper {
    display: flex;
    justify-content: center;
    span {
      display: block;
      max-width: 350px;
      padding-bottom: 20px;
      font-size: 16px;
      text-align: center;
    }
  }
  &__calendar-modal {
    h2 {
      padding: 10px 0;
      text-align: center;
    }
    .btn {
      width: 100%;
      &__yes {
        margin-right: 20px;
      }
    }
    .row {
      justify-content: center;
    }
  }
}
