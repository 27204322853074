.benefits {
  &__content {
    margin-top: 20px;
    @include media-breakpoint-tablet() {
      margin-top: 40px;
    }
    .row {
      justify-content: center;
    }
    span {
      font-size: $font-size-sm;
    }
    .icon {
      margin-top: 5px;
    }
  }
  &__item {
    margin-bottom: 10px;
  }
}
