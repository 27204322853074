.therapist-profile-image {
  &__wrapper {
    height: 99px;
    width: 99px;
    position: relative;
    &--show-profile {
      width: 92px;
      min-width: 92px;
      height: 107px;
      cursor: pointer;
    }
  }
  &__button-image {
    height: 100%;
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
    cursor: inherit !important;
    &:focus,
    &:active {
      outline: none;
    }
  }
}

.therapist-card__image {
  z-index: 3;
}
.therapist-card__play {
  z-index: 4
}
