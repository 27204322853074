.therapist-prebooking-list {
  &__prebooking-item-wrapper {
    display: flex;
    margin-bottom: 34px;
    position: relative;
  }
  &__prebooking-item {
    width: 100%;
    position: relative;
  }
  &__button-trash {
    background: none;
    box-shadow: none;
    border: 0;
    color: $salmon;
    padding: 15px;
    &:not(:disabled):not(.disabled):hover {
      background-color: transparent;
      border-color: transparent;
      color: $danger;
    }
  }
  &__add-date-input {
    margin-right: 10px;
  }
  &__calendar-icon-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-50%, 25%);
  }
  &__calendar-icon {
    width: 38px;
    height: 38px;
    border-radius: 6px;
    background-color: $light;
    padding: 8px;
  }
  &__empty-list {
    display: block;
    font-size: $font-size-sm;
  }
  &__render-view-buttons {
    display: flex;
    justify-content: center;
  }
}
