.user-row {
  margin-bottom: 60px;
  font-size: $font-size-sm;

  &__row {
    align-items: flex-start;
    color: $text-color-light;
  }
  &__cell {
    justify-content: flex-end;

    strong {
      display: block;
      color: $text-color;
      font-size: $font-size-md;
    }
  }
  &__title {
    font-size: 20px;
    color: $text-color;
    @include media-breakpoint-tablet() {
      margin-bottom: 0;
    }
  }
  &__contact {
    a {
      color: $text-color;
    }
  }
  &__note {
    width: 100%;
    @include media-breakpoint-tablet() {
      padding: 0 15px;
      width: 62.5%;
    }
  }
  &__note-wrapper {
    @include media-breakpoint-tablet() {
      display: flex;
      justify-content: flex-end;
    }
  }
  &__info {
    circle {
      fill: #F5F0EB
    }
  }
}
