@use 'sass:color';

html [data-lk-theme='default'] {
  $bg: $body-bg;
  $fg: white;
  $danger: $error;
  $success: $secondary;
  $warning: $primary;
  $accent: $primary;

  color-scheme: light;

  --lk-bg: #{$bg};
  --lk-bg2: #{$bordeaux-background};
  --lk-bg3: #{color.adjust($bg, $lightness: 10%)};
  --lk-bg4: #{color.adjust($bg, $lightness: 15%)};
  --lk-bg5: #{color.adjust($bg, $lightness: 20%)};

  --lk-fg: #{$bordeaux};
  --lk-fg2: #{color.adjust($fg, $lightness: -4%)};
  --lk-fg3: #{color.adjust($fg, $lightness: -8%)};
  --lk-fg4: #{color.adjust($fg, $lightness: -12%)};
  --lk-fg5: #{color.adjust($fg, $lightness: -16%)};

  --lk-border-color: rgba(255, 255, 255, 0.1);

  --lk-accent-fg: #{$text-color};
  --lk-accent-bg: #{$tertiary};
  --lk-accent2: #{color.adjust($accent, $lightness: 4%)};
  --lk-accent3: #{color.adjust($accent, $lightness: 8%)};
  --lk-accent4: #{color.adjust($accent, $lightness: 12%)};

  --lk-danger-fg: #fff;
  --lk-danger: #{$danger};
  --lk-danger2: #{color.adjust($danger, $lightness: 4%)};
  --lk-danger3: #{color.adjust($danger, $lightness: 8%)};
  --lk-danger4: #{color.adjust($danger, $lightness: 12%)};

  --lk-success-fg: #fff;
  --lk-success: #{$success};
  --lk-success2: #{color.adjust($success, $lightness: 4%)};
  --lk-success3: #{color.adjust($success, $lightness: 8%)};
  --lk-success4: #{color.adjust($success, $lightness: 12%)};

  --lk-control-fg: var(--lk-fg);
  --lk-control-bg: #{$session-box-bg};
  --lk-control-hover-bg: #{$body-bg};
  --lk-control-active-bg: #{$session-box-bg};
  --lk-control-active-hover-bg: #{$body-bg};

  // --lk-connection-excellent: #06db4d;
  // --lk-connection-good: #f9b11f;
  // --lk-connection-poor: #f91f31;

  // --lk-font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
  // 'Apple Color Emoji', 'Segoe UI Emoji';
  // --lk-font-size: 16px;
  // --lk-line-height: 1.5;
  // --lk-border-radius: 0;
  // --lk-box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);

  --lk-grid-gap: 0rem;
  --lk-control-bar-height: 80px;
  // --lk-chat-header-height: 69px;
}

.video-room .lk-prejoin {
  --lk-bg: transparent;
  --lk-fg: #{$text-color-light};

  .lk-button {
    overflow: hidden;
  }

  padding: 0;
  margin: 0;
  // background-color: white;
  width: 100%;

  .lk-join-button {
    @extend .btn;
    @extend .btn-primary;
    width: auto;
  }

  .lk-video-container {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
  }
  .lk-username-container {
    display: none;
  }

  .lk-button-group-container {
    flex-wrap: wrap;
    margin-right: 16px;

    font-size: 13px;

    & > .lk-button-group {
      width: 100%;
    }

    @media screen and (min-width: 992px) {
      flex-wrap: nowrap;

      & > .lk-button-group {
        width: 50%;
      }
    }
  }
}

.lk-room-container {
  height: 100vh !important; // fallback for old browsers
  height: 100dvh !important;
}

.lk-video-conference {
  --lk-fg: white;
  --lk-border-radius: 0;

  overflow: hidden;

  .lk-participant-media-video {
    background: $call-participant-bg;
  }

  .lk-grid-layout {
    padding: 0;
  }

  .lk-participant-tile {
    border-radius: 0;

    &:after {
      border: 0;
    }
  }

  .lk-participant-media-video {
    object-fit: contain !important;
  }

  .lk-control-bar {
    z-index: 9;
    gap: 20px;
    border-top: none;

    @include media-breakpoint-big-phone() {
      gap: 36px;
    }

    &--hidden {
      display: none;
    }
  }

  .lk-participant-metadata {
    color: white;
  }

  // this is a minimal version of the metadata object
  // it can be uncommented if we want mute indicator & network quality indicator
  // .lk-participant-metadata-item {
  //   & > * {
  //     color: white;
  //     display: none;

  //     &.lk-track-muted-indicator-microphone {
  //       display:block
  //     }
  //   }

  //   &.lk-connection-quality > * {
  //     opacity: 0.5;
  //     display: block;
  //   }
  // }

  .lk-own-preview {
    .lk-participant-metadata {
      display: none;
      margin-top: calc(#{$call-top-controls-max-height} + 20px);
    }
  }

  .lk-video-conference-inner .lk-participant-media-video {
    object-fit: cover !important;

    @include media-breakpoint-tablet() {
      object-fit: contain !important;
    }

    &[data-lk-source='screen_share'] {
      object-fit: contain !important;
    }
  }

  // .lk-grid-layout > [data-lk-local-participant='true'] {
  //   display: none;
  //   &:only-child,
  //   &[data-lk-source='screen_share'] {
  //     display: block;
  //   }
  // }
}

.lk-grid-layout-wrapper:has(+ .lk-control-bar--hidden) {
  height: 100%;
}

.call {
  &__options {
    .lk-button {
      background-color: white;
      color: $text-color;
      text-align: left;
      white-space: unset;

      @include media-breakpoint-tablet() {
        font-size: 14px;
        white-space: nowrap;
      }
    }

    .lk-list {
      li {
        border-top: 1px solid $bordeaux-background;
        padding: 8px 0 8px 35px;

        &:hover {
          background-color: $alternative-bg;

          .lk-button {
            background-color: $alternative-bg;
          }
        }

        @include media-breakpoint-tablet() {
          padding: 6px 0 6px 35px;
        }
      }
    }

    .lk-media-device-select li:not(:last-child),
    .lk-media-device-select li {
      margin-bottom: 0;
    }

    .last-list.lk-media-device-select li:last-child {
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    .lk-media-device-select [data-lk-active='true'] > .lk-button {
      font-weight: bold;

      &:after {
        content: '\2713';
        color: $primary;
        font-weight: bold;
      }
    }

    .lk-media-device-select li > .lk-button {
      border-bottom-right-radius: 8px;
    }

    .lk-media-device-select li[data-lk-active='true'] {
      background: var(--lk-accent-bg);

      &:hover {
        background: var(--lk-accent-bg);

        .lk-button {
          background: var(--lk-accent-bg);
        }
      }
    }

    .lk-media-device-select li:not([data-lk-active='true']) > .lk-button:not(:disabled):hover {
      &:hover {
        background-color: $alternative-bg;

        .lk-button {
          background-color: $alternative-bg;
        }
      }
    }
  }
}

// .lk-prejoin .lk-video-container  {
//   border-radius: 8px;

//   video {
//     width: 100%;
//     height: 100%;
//   }
// }

// .lk-button-group-container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .lk-button-group {
//   display: flex;
//   justify-content: space-between;
// }

// .lk-button-group.audio,
// .lk-button-group.video {
//   justify-content: unset;
//   margin: 16px 8px 0 0;

//   .lk-button-group-menu {
//     display: none;
//   }

//   .lk-button {
//     background: $bordeaux-background;
//     border: 0;
//     border-radius: 50%;
//     width: 40px;
//     height: 40px;

//     svg {
//       fill: $peach;
//     }
//   }
// }

// .lk-prejoin .lk-button-group-container>.lk-button-group {
//   width: 41px !important;
//   height: 41px;
// }

// .lk-media-device-select {
//   list-style: none;
//   padding: 0;
//   position: absolute;
//   background: white;
//   left: 0;
//   width: 100%;
//   z-index: 10;
//   border: 1px solid $bordeaux-lighter;
//   border-radius: 4px;

//   .lk-button {
//     background: transparent;
//     border: 0;
//     font-size: 14px;
//     color: $bordeaux;
//     cursor: pointer;
//     width: 100%;
//     text-align: left;
//     padding: 12px 16px;
//   }
// }

// .lk-camera-off-note {
//   display: flex;
//   justify-content: center;
//   background: $bordeaux-background;
//   border-radius: 8px;
// }
