.contact {
  position: relative;
  padding: 55px 0;
  outline: none;
  @include media-breakpoint-up(lg) {
    padding: 90px 0;
  }
  @include media-breakpoint-up(xl) {
    padding: 120px 0;
  }
  &__title {
    margin-bottom: 20px;
    color: $cyan;
    font-weight: 600;
    text-align: center;
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
  }
  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    @include media-breakpoint-up(lg) {
      flex-flow: row;
      align-items: center;
    }
  }
  &__info {
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 18px;
    @include media-breakpoint-up(lg) {
      flex-basis: 45%;
      max-width: 45%;
      margin: 0;
      padding-right: 70px;
    }
    &:not(&--compact) {
      h3 {
        margin-bottom: 20px;
        font-weight: 800;
        font-size: 30px;
        line-height: 56px;
      }
    }
    &-wrapper {
      padding: 30px 20px;
      background: $white;
      border-radius: 12px;
      @include media-breakpoint-up(lg) {
        padding: 40px 50px;
      }
    }
    p {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    hr {
      width: 100%;
      height: 2px;
      margin: 30px 0;
      background: url('/images/svg/styleElements/dots-horizontal.svg') left center repeat-x;
      border: 0;
    }
  }
  &__info--alt {
    max-width: none;
    text-align: center;
    @include media-breakpoint-up(lg) {
      flex-basis: 100%;
      width: 100%;
      max-width: none;
      padding-right: 15px;
    }
    .contact {
      &__company-info {
        max-width: 250px;
        margin: 0 auto;
        &-label {
          font-weight: $font-weight-bold;
          font-size: $font-size-sm;
          text-transform: uppercase;
        }
        &-icon {
          margin-right: 15px;
          vertical-align: text-top;
        }
        &-contact {
          display: inline-block;
          width: 100%;
          margin-bottom: 0;
          color: $primary;
          font-weight: $font-weight-bold;
          vertical-align: text-top;
        }
        &-hours {
          font-weight: $font-weight-bold;
        }
        &-note {
          color: $text-color-light;
        }
      }
    }
  }
  &__company-info {
    &-ico {
      margin-bottom: 20px;
    }
    &-phone,
    &-email {
      position: relative;
      display: block;
      padding-left: 25px;
      color: $body-color;
      text-decoration: none;
      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 14px;
        height: 14px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        transform: translate(-0%, -50%);
        content: '';
      }
      &:hover {
        color: $body-color;
        text-decoration: underline;
      }
    }
    &-phone::after {
      background-image: url('/images/svg/contactUs/phone.svg');
    }
    &-email::after {
      background-image: url('/images/svg/contactUs/envelope.svg');
    }
  }
  &__image {
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      flex-basis: 55%;
      max-width: 55%;
    }
    img {
      max-width: 100%;
      height: auto;
      border-radius: 30px;
    }
  }
  &__img {
    width: 100%;
    max-width: 100%;
    border-radius: 30px;
  }
}

.contact--compact,
.contact--inline {
  .contact {
    &__meta {
      &-link {
        position: relative;
        display: inline-block;
        margin: 12px 12px 0;
        padding-left: 48px;
        color: inherit;
        font-weight: $font-weight-bold;
        text-decoration: none;
        transition: color 0.4s;
        &::after {
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          width: 32px;
          height: 32px;
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: center center;
          transform: translate(-0%, -50%);
          content: '';
        }
        &:hover {
          color: $cyan;
          text-decoration: none;
        }
      }
      &-link--phone {
        &::after {
          background-image: url('/images/svg/contactUs/phone-green.svg');
        }
        span {
          font-weight: 400;
        }
      }
      &-link--email {
        &::after {
          background-image: url('/images/svg/contactUs/envelope-green.svg');
        }
        span {
          text-decoration: underline;
        }
      }
    }
  }
}

.contact--compact {
  .contact {
    &__wrapper {
      max-width: 700px;
      margin: 0 auto;
    }
    &__info {
      @include media-breakpoint-up(lg) {
        padding-right: 40px;
        text-align: right;
      }
      &-wrapper {
        padding: 0;
        background-color: transparent;
      }
    }
    &__company {
      &-info {
        @include media-breakpoint-up(lg) {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        p {
          // stylelint-disable-next-line selector-max-compound-selectors
          + hr {
            margin-top: 10px;
          }
        }
      }
    }
    &__meta {
      max-width: 880px;
      margin: 40px auto 0;
      padding: 18px;
      text-align: center;
      background-color: $white;
      border-radius: 12px;
      @include media-breakpoint-up(lg) {
        padding: 32px;
      }
      &-title {
        margin-bottom: 8px;
      }
    }
  }
}

.contact--inline {
  .container {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
    }
  }
  .contact {
    &__title {
      max-width: 500px;
      font-weight: 800;
      text-align: left;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
    &__info {
      flex-basis: auto;
      max-width: none;
      margin-left: 0;
      padding: 0;
      @include media-breakpoint-up(lg) {
        min-width: 370px;
        margin-left: auto;
      }
    }
    &__info-wrapper {
      padding: 0;
      background-color: transparent;
      @include media-breakpoint-up(lg) {
        padding: 0 16px 0 80px;
      }
    }
    &__company-info {
      h3 {
        margin-bottom: 8px;
        font-size: $font-size-lg;
        line-height: 30px;
      }
      p {
        color: $text-color-light;
        line-height: 28px;
      }
      strong {
        color: $text-color;
      }
    }
    &__meta {
      @include media-breakpoint-up(lg) {
        max-width: 275px;
      }
      &-link {
        margin: 16px 16px 16px 0;
        &::after {
          width: 48px;
          height: 48px;
          background-color: $beige;
          background-size: 24px;
          border-radius: 6px;
        }
      }
      &-link--phone {
        &::after {
          background-image: url('/images/svg/contactUs/phone-orange.svg');
        }
      }
      &-link--email {
        &::after {
          background-image: url('/images/svg/contactUs/envelope-orange.svg');
        }
      }
      span {
        margin-left: 8px;
      }
    }
    &__note {
      display: inline-block;
      font-size: $font-size-base;
      line-height: 26px;
    }
  }
}
