.icon {
  display: inline-block;
}

.icon--xxs {
  width: 12px;
  min-width: 12px; // fix for possible flex
  height: 12px;
}

.icon--xs {
  width: 15px;
  min-width: 15px; // fix for possible flex
  height: 15px;
}

.icon--sm {
  width: 22px;
  min-width: 22px; // fix for possible flex
  height: 22px;
}

.icon--md {
  width: 30px;
  min-width: 30px; // fix for possible flex
  height: 30px;
}

.icon--slg {
  width: 50px;
  min-width: 50px; // fix for possible flex
  height: 50px;
}

.icon--lg {
  width: 75px;
  min-width: 75px; // fix for possible flex
  height: 75px;
}

.icon--xlg {
  width: 90px;
  min-width: 90px; // fix for possible flex
  height: 90px;
}

.icon--xl {
  width: 150px;
  min-width: 150px; // fix for possible flex
  height: 150px;
}

.icon-text {
  display: flex;
  &__icon {
    margin-left: 15px;
    &.icon--native {
      width: 18px;
      min-width: 18px; // fix for possible flex
      height: 18px;
    }
  }
  &__text {
    &--info {
      color: $text-color;
      font-size: $font-size-sm;
    }
  }
}

.icon-text--center {
  align-items: center;
  .icon-text {
    &__icon {
      margin-top: 0;
    }
  }
}

.icon-text--reversed {
  .icon-text {
    &__icon {
      margin-right: 15px;
      margin-left: 0;
    }
  }
}
