.hidden-therapist {
  &__text-wrapper {
    text-align: center;
  }
  &__label {
    margin-bottom: 30px;
    color: $success;
    font-size: 32px;
    font-weight: 800;
    line-height: 34px;
  }
  &__info {
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
  }
  &__card-list {
    justify-content: center;
  }
  &__card {
    margin: 10px 0;
    border-radius: $border-radius-lg;
    background-color: $white;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    padding: 20px;
    box-shadow: 0 4px 40px rgba(211, 155, 123, 0.2);

    &:hover {
      box-shadow: 0 4px 40px rgba(211, 155, 123, 0.4);
    }
  }
  &__card-image-wrapper {
    border-radius: 6px;
    max-width: 125px;
  }
  &__card-image {
    border-radius: 6px;
    width: 100%;
  }
  &__card-name {
    padding-left: 30px;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
  }
}
