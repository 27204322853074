.react-select {
  flex-basis: 100%;
  width: 100%;
  max-width: 240px;

  &--is-invalid {
    border-color: $error;

    .react-select-searchable__control {
      border-color: $error !important;
    }
  }

  // nested to avoid overwrite
  .react-select {
    &__control {
      position: relative;
      padding: 3px 20px;
      min-width: 200px;
      color: $text-color-light;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 13px;
      background-color: $session-box-bg;
      border: 0;
      border-radius: 40px;
      cursor: pointer;
      transition: background-color 0.2s;
      // width: 100%;

      .icon {
        color: $primary;
      }

      &--is-focused,
      &--menu-is-open {
        color: $white;
        background-color: $primary !important; // exception to overwrite react-select stlyes
        box-shadow: none;
        .icon {
          color: $white;
        }
        .react-select {
          &__multi-value {
            color: $white;
            background-color: lighten($primary, 5%);
          }
        }
        input {
          color: $white !important; // exception to overwrite react-select stlyes
        }
      }
      &:hover {
        background-color: darken($light, 4%);
      }
      input {
        font-weight: 700;
      }
    }
    &__indicator-separator {
      display: none;
    }
    &__indicator {
      position: absolute;
      top: 50%;
      right: 6px;
      color: inherit;
      transform: translateY(-50%);
      &:hover {
        color: inherit;
      }
      svg {
        width: 14px;
        height: 14px;
      }
    }
    &__clear-indicator {
      right: auto;
      left: 6px;
    }
    &__value-container {
      justify-content: start;
      padding-right: 10px;
    }
    &__placeholder,
    &__single-value {
      color: inherit;
    }
    &__multi-value {
      flex-direction: row-reverse;
      padding-right: 5px;
      background-color: $light;
      border-radius: $border-radius-sm;
    }
    &__multi-value__label {
      padding: 2px 2px 2px 0;
      color: inherit;
      cursor: default;
    }
    &__multi-value__remove {
      &:hover {
        color: inherit;
        background-color: inherit;
      }
    }
    &__menu {
      z-index: 10;
      padding: 14px 0;
      border-radius: $border-radius;
      box-shadow: none;
    }
    &__option {
      position: relative;
      display: flex;
      padding: 4px 10px;
      font-size: $font-size-sm;
      cursor: pointer;
      transition: background-color 0.2s;
      &:active {
        background-color: darken($light, 4%);
      }
      &::before {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-bottom: 2px;
        vertical-align: middle;
        background-image: url('data:image/svg+xml,%3Csvg fill="none" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"%3E%3Crect x="1" y="1" width="17" height="17" rx="3" stroke="%23490811" stroke-width="2"/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        content: '';
      }
    }
    &__option--is-focused {
      background-color: darken($light, 4%);
    }
    &__option--is-selected {
      color: inherit;
      background-color: transparent;
      &:hover {
        background-color: darken($light, 4%);
      }
      &::before {
        background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"%3E%3Crect width="19" height="19" rx="4" fill="%23FF8F67"/%3E%3Cpath d="M13.9 6.22a.75.75 0 00-1.07.02l-3.48 4.43-2.09-2.1A.75.75 0 006.2 9.63l2.65 2.65a.75.75 0 001.08-.02l3.99-5a.75.75 0 00-.01-1.04z" clip-rule="evenodd" fill="%23fff" fill-rule="evenodd"/%3E%3C/svg%3E');
      }
    }
  }
}
.react-select--searchable {
  max-width: 100%;
  .react-select-searchable {
    &__control {
      border: 2px solid $light-brown-trans;
      padding: 12px;
      max-height: 60px;
      border-radius: $border-radius;
      &--is-focused,
      &--menu-is-open {
        box-shadow: none;
      }
    }
    &__value-container {
      padding: 0;
    }
    &__indicator-separator {
      width: 0;
    }
    &__dropdown-indicator {
      background-color: $light;
      border-radius: 8px;
      svg {
        color: $text-color;
      }
    }
    &__placeholder {
      color: $text-color-light;
    }
    &__menu {
      z-index: 99999;
    }
    &__option {
      position: relative;
      padding: 4px 20px;
      font-size: $font-size-sm;
      cursor: pointer;
      transition: background-color 0.2s;
      &:active {
        background-color: darken($light, 4%);
      }
    }
  }
}

.react-select.react-select--single-value {
  .react-select__option {
      &::before {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-bottom: 2px;
        vertical-align: middle;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTMiIGN5PSIxMyIgcj0iMTIiIHN0cm9rZT0iIzQ5MDgxMSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        content: '';
      }

    &--is-selected {
      &::before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTMiIGN5PSIxMyIgcj0iMTIiIHN0cm9rZT0iI0ZGOEY2NyIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxjaXJjbGUgY3g9IjEzLjIiIGN5PSIxMy4yIiByPSI1LjIiIGZpbGw9IiNGRjhGNjciLz4KPC9zdmc+Cg==)
        }
      }
    }
}

.react-select .react-select__menu {
  box-shadow: 0 7px 30px rgb(211 155 123 / 30%);
}
