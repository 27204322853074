.account-item {
  align-items: center;
  margin: 10px 0;
  padding: 25px 35px;
  line-height: 24px;
  border-radius: $border-radius-lg;
  @include media-breakpoint-tablet() {
    padding: 25px 50px;
  }
  .icon-text {
    display: flex;
    align-items: center;
  }
  &__download-invoice {
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      color: $primary;
      font-weight: bold;
    }
  }
  &__payments {
    display: flex;
    margin-top: 10px;
    @include media-breakpoint-tablet() {
      align-items: center;
      justify-content: flex-start;
      margin-top: 0;
    }
  }
}
