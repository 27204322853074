/* ==========================================================================
Custom variables
========================================================================== */

/* ==========================================================================
Grid breakpoints
========================================================================== */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1240px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1180px,
);

/* ==========================================================================
Site Header
========================================================================== */
$site-header-height: 93px;
$site-header-lg-height: 159px;

/* ==========================================================================
Colors
========================================================================== */
$atoll: #2f747a;
$aluminium: #9e9395;
$salmon: #ff8f67;
$chablis: #ffe9e1;
$aubergine: #430e13;
$brown: #490811;
$wood-bark: #322426;
$woody-brown: #473134;
$light-brown: #82555c;
$rebel: #443234;
$russet: #7c575c;
$mountain-mist: #9c9395;
$redish-brown: #a98687;
$martini: #b4a0a3;
$beige: #fdf7f2;
$light-blue: #e6f2f0;
$sand: #fdf7f1;
$aqua-dark: #c8dfdf;
$white: #ffffff;
$ice-rink-blue: #e5e5e5;
$petroleum: #adcccc;
$cyan: #2f747a;
$desert-storm: #f1ebe5;
// $primary: $salmon;
// $secondary: $atoll;
// $danger: #fa3c53;
$warning: #fbbc05;
$info: aqua;
$success: green;
// $light-grey: $ice-rink-blue;

$body-color: $text-color;

$btn-shadow: 0px 7px 30px rgba(211, 155, 123, 0.3);

$link-hover-color: $body-color;

/* ==========================================================================
Text colors
========================================================================== */
$text-colors: (
  'primary': $primary,
  'secondary': $secondary,
);

/* ==========================================================================
Alert colors
========================================================================== */
$alert-border-radius: 8px;

$alert-colors: (
  'danger': $danger,
  'warning': $warning,
  'success': $success,
);

/* ==========================================================================
Background Colors
========================================================================== */
$bg-colors: (
  'default': $white,
  'white': $white,
  'aqua': $aqua,
  'sand': $sand,
  'brown': $brown,
  'wood-bark': $wood-bark,
  'light-grey': $light-grey,
);

/* ==========================================================================
Typography
========================================================================== */

// Body
$body-font-size: 1em; // 16px
$body-font-size-lg: $body-font-size * 1.125; // 18px

$line-height-base: 1.8; // 30px

// H1
$h1-font-size: 30px;
$h1-line-height: 42px;

$h1-font-size-lg: 44px;
$h1-line-height-lg: 56px;

// H2
$h2-font-size: 24px;
$h2-line-height: 32px;

$h2-font-size-lg: 30px;
$h2-line-height-lg: 40px;

// H3
$h3-font-size: 22px;
$h3-line-height: 28px;

$h3-font-size-lg: 24px;
$h3-line-height-lg: 34px;

// H4
$h4-font-size: 26px;
$h4-line-height: 36px;

$h4-font-size-lg: 34px;
$h4-line-height-lg: 44px;

// Display 1
$display1-font-size: 36px;
$display1-line-height: 48px;

$display1-font-size-lg: 44px;
$display1-line-height-lg: 56px;

$font-weight-bolder: 700;

/* ==========================================================================
Buttons
========================================================================== */
// Colors
$button-colors: (
  'primary': $primary,
  'light': $light,
  'danger': $danger,
);

// Regular
$btn-font-size: 16px;
$btn-font-weight: 600;
$btn-border-radius: 99px;
$btn-box-shadow: 0px 7px 30px rgba(211, 155, 123, 0.3);
$btn-box-shadow-hover: 0px 7px 30px rgba(211, 155, 123, 0.6);
$btn-padding-x: 26px;
$btn-padding-y: 19px;

// Small
$btn-sm-font-size: 13px;
$btn-sm-padding-x: 18px;
$btn-sm-padding-y: 7px;

/* ==========================================================================
Forms
========================================================================== */
$input-padding-y: 0.9125rem;
$input-padding-x: 1.875rem;
$input-bg: $white;
$input-color: $text-color-light;
$input-border-width: 2px;
$input-border-radius: 8px;
$input-border-color: $text-color-light;
$input-btn-border-width: $input-border-width;
$input-placeholder-color: rgba($light-brown, 0.3);

$custom-control-indicator-size: 1.25rem;
$custom-control-indicator-bg: transparent;
$custom-control-indicator-border-color: $brown;
$custom-control-indicator-border-width: 2px;

$custom-checkbox-indicator-border-radius: 4px;

$input-group-addon-bg: $input-bg;

/* ==========================================================================
Badges
========================================================================== */
$badge-colors: (
  'primary': $primary,
  'light': $light,
  'dawn-pink': $dawn-pink,
);

$badge-padding-y: 10px;
$badge-padding-x: 15px;

$badge-sm-padding-y: 8px;
$badge-sm-padding-x: 12px;

/* ==========================================================================
Tables
========================================================================== */
$table-cell-padding: 16px;
$table-border-color: $dawn-pink;
$table-border-width: 2px;

/* ==========================================================================
Modal
========================================================================== */
$modal-backdrop-opacity: 0.3;
$modal-content-border-color: transparent;
$modal-content-border-width: 0;
$modal-content-border-radius: 20px;

/* ==========================================================================
Scrollbar
========================================================================== */
$scrollbar-width: 6px;
$scrollbar-height: $scrollbar-width;
$scrollbar-track-bg: $light;
$scrollbar-border-radius: 42px;
$scrollbar-handle-bg: $primary;
$scrollbar-handle-hover-bg: $brown;
$scrollbar-offset: 14px;
