/* ==========================================================================
Site header
========================================================================== */
.site__header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-sticky;
  width: 100%;
  padding: 22px 14px;
  box-shadow: 0 0 6px 2px rgba($brown, 0);
  transition: transform 0.2s, background-color 0.2s, box-shadow 0.2s;
  @include media-breakpoint-down(lg) {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $sand;
      transition: background-color 0.2s;
      content: '';
    }
  }

  &-logo {
    position: relative;
    z-index: 1;

    &-img {
      width: 100%;
      max-width: 120px;
      height: 120px;
      max-height: 41px;
      @include media-breakpoint-up(xl) {
        max-width: 190px;
        max-height: 65px;
      }
    }
  }

  &-nav {
    order: 1;
    margin: 0;
    padding: 0 0 30px;
    list-style-type: none;
    @include media-breakpoint-up(xl) {
      order: initial;
      padding-bottom: 0;
    }

    .sub-menu {
      &__title {
        display: inline-block;
        margin-bottom: 8px;
        padding: 16px 25px;
        font-weight: $font-weight-bold;
        font-size: $font-size-lg;
      }

      &__title--dropdown {
        margin-bottom: 0;
        font-size: $font-size-base;
        cursor: pointer;
      }

      &__caret {
        position: relative;
        @include media-breakpoint-up(xl) {
          @include border-top-radius(12px);
          position: absolute;
          top: calc(100% - 60px);
          right: 0;
          z-index: -1;
          width: 100%;
          height: 70px;
          background-color: $white;
        }

        &::before {
          position: absolute;
          right: 100%;
          bottom: 0;
          width: 15px;
          height: 11px;
          background-image: url('data:image/svg+xml,%3Csvg fill="none" viewBox="0 0 15 11" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 11c10-.5 14.1-7.6 15-11v11H0z" fill="%23fff"/%3E%3C/svg%3E');
          background-repeat: no-repeat;
          background-size: cover;
          content: '';
        }
      }

      &__caret--shifted {
        &::after {
          position: absolute;
          bottom: 0;
          left: 100%;
          width: 15px;
          height: 11px;
          background-image: url('data:image/svg+xml,%3Csvg fill="none" viewBox="0 0 15 11" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 11c10-.5 14.1-7.6 15-11v11H0z" fill="%23fff"/%3E%3C/svg%3E');
          background-repeat: no-repeat;
          background-size: cover;
          transform: scaleX(-1);
          content: '';
        }
      }
    }

    .sub-menu--contact {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 15px;
      padding: 32px 20px 25px;
      list-style-type: none;
      border-radius: 20px;
      @include media-breakpoint-up(xl) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0 0;
        padding: 10px 20px;
      }
      > .menu-item {
        width: 100%;
        max-width: 200px;
        text-align: left;
        @include media-breakpoint-up(xl) {
          width: auto;
        }
      }
      .submenu-contact {
        padding: 0;
        line-height: 1.2;
        background-color: transparent;
        @include media-breakpoint-up(xl) {
          max-width: 160px;
          white-space: normal;
        }
      }
      .sub-menu__title {
        margin-bottom: 14px;
        padding: 0;
        @include media-breakpoint-up(xl) {
          margin-bottom: 0;
          padding: 15px 25px;
        }
      }
    }

    .menu-item--contact {
      margin: 2px 0;
      @include media-breakpoint-up(xl) {
        margin: 0 30px;
        white-space: nowrap;
        &:last-of-type {
          margin-right: 15px;
        }
      }
    }
  }

  &-nav--redesigned {
    padding-bottom: 0;
    text-align: left;
    .site__header {
      // stylelint-disable-next-line selector-max-compound-selectors
      &-nav-item:not(.site__header-nav-item--subitem) {
        > .sub-menu {
          background-color: $white;
        }
        .menu-item-contact-wrapper {
          padding: 10px 0;
        }
      }

      &-nav-item-link {
        padding: 16px 30px;
      }
      &-nav-item-link--without-padding {
        width: auto;
        padding: 0;
      }
      &-nav-item-link--submenu-active {
        background-color: $secondary;
        @include media-breakpoint-up(xl) {
          background-color: transparent;
        }
      }

      &-nav-item--subitem {
        background-color: $aqua;
        @include media-breakpoint-up(xl) {
          background-color: transparent;
        }
        &:not(:last-of-type) {
          .sub-menu {
            border-color: $aqua-dark;
            border-width: 2px;
          }
        }
        &:nth-last-of-type(2) {
          .sub-menu {
            border: 0;
          }
        }
        .menu-item {
          background-color: transparent;
        }
      }
    }
  }

  &-nav-item {
    position: relative;
    @include media-breakpoint-up(xl) {
      display: inline-block;
      filter: drop-shadow(0 7px 30px rgba(211, 155, 123, 0.3));
    }

    &:not(&--subitem) {
      > .sub-menu {
        display: none;
        margin: 0;
        padding: 0;
        list-style-type: none;
        background-color: $aqua;
        @include media-breakpoint-up(xl) {
          position: absolute;
          top: calc(100% + 10px);
          right: 0;
          padding: 70px 25px 25px;
          white-space: nowrap;
          background-color: $white;
          border-radius: 20px;
        }
        // exception keep modified bellow query for overwrites
        &:not(.sub-menu--shifted) {
          @include media-breakpoint-up(xl) {
            @include border-top-right-radius(0);
          }
        }
        &.sub-menu--shifted {
          white-space: normal;
          > .menu-item {
            &:first-of-type {
              @include media-breakpoint-up(xl) {
                margin-top: 40px;
              }
            }
          }
          > *:not(.menu-item-contact-wrapper):not(.menu-item) {
            margin: 0 15px;
            &:first-child {
              padding-top: 15px;
              @include media-breakpoint-up(xl) {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }

  &-nav-item--with-submenu-title {
    .sub-menu {
      @include media-breakpoint-up(xl) {
        &:not(.sub-menu--shifted):not(.sub-menu--contact) {
          right: calc(50% - 35px);
          width: 942px;
          padding: 40px 32px 32px;
          white-space: normal;
          border-top-right-radius: 20px;
          transform: translateX(50%);
          .sub-menu--contact {
            margin-top: 20px;
          }
        }
        &__caret {
          &::after {
            position: absolute;
            bottom: 0;
            left: 100%;
            width: 15px;
            height: 11px;
            background-image: url('data:image/svg+xml,%3Csvg fill="none" viewBox="0 0 15 11" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 11c10-.5 14.1-7.6 15-11v11H0z" fill="%23fff"/%3E%3C/svg%3E');
            background-repeat: no-repeat;
            background-size: cover;
            transform: scaleX(-1);
            content: '';
          }
        }
      }
      &:not(.sub-menu--shifted):not(.sub-menu--contact) {
        padding: 26px 18px 12px;
        .menu-item-contact-wrapper {
          padding-top: 0;
        }
        .sub-menu--contact {
          margin: 0;
        }
      }
      > h3 {
        width: 100%;
        margin-bottom: 7px;
        @include media-breakpoint-up(xl) {
          font-size: 22px;
        }
      }
      > .lead {
        display: block;
        font-size: 16px;
        line-height: 1.6;
        @include media-breakpoint-up(xl) {
          margin-bottom: 22px;
          font-size: 18px;
        }
      }
      .site__header-nav-item--subitem:not(.submenu-contact) {
        margin: 30px 0;
        background-color: transparent;
        @include media-breakpoint-up(xl) {
          width: 45%;
          margin: 18px 0;
        }
      }
    }
  }

  &-nav-item--subitem {
    &:not(&-nested) {
      @include media-breakpoint-up(xl) {
        padding: 0 20px;
      }
    }

    &:not(:last-of-type) {
      .sub-menu {
        border-bottom: 1px solid $border-color;
        @include media-breakpoint-up(xl) {
          border-bottom: 0;
        }
      }
    }

    .sub-menu {
      display: flex;
      flex-direction: column;
      padding-left: 0;
    }
  }

  &-nav-item-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.2;
  }

  &-nav-item-desc {
    color: $body-color;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
  }

  &-nav-item-link {
    display: inline-block;
    padding: 16px 25px;
    color: $secondary;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &-nav-item-link--without-padding {
    width: auto;
    padding: 0;
  }

  &-nav-item-link--subitem {
    width: 100%;
    @include media-breakpoint-up(xl) {
      transition: color 0.2s;
      &:hover {
        color: $primary;
      }
      &::after {
        display: none;
      }
    }
  }

  &-nav-item-link--subitem-nested {
    padding: 10px 25px;
  }

  &-nav-item-link--active {
    color: $primary;
    @include media-breakpoint-up(xl) {
      &:hover {
        color: $primary;
        background-color: transparent;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 25px;
        width: calc(100% - 50px);
        height: 2px;
        background-color: $primary;
        content: '';
      }
    }
  }

  &-nav-item-link--submenu-active {
    @include media-breakpoint-down(lg) {
      color: $white;
      background-color: $primary;
      &:hover {
        color: $white;
      }
    }
    @include media-breakpoint-up(xl) {
      color: $primary;
    }

    .site__header-nav-icon {
      transform: rotate(-180deg);
    }
  }

  &-nav-item-icon-wrapper {
    display: inline-block;
    float: left;
    width: 55px;
    margin-right: 10px;
  }

  &-nav-item-icon {
    width: auto;
    max-width: 100%;
    height: 35px;
    object-fit: contain;
  }

  &-nav-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    vertical-align: middle;
    transition: transform 0.2s;
  }

  &-mobile-top-links {
    position: relative;
    z-index: 2;
    display: none;
    .site__navigation-upper-link {
      color: $white;
      &:hover {
        color: $white;
      }
    }
  }

  &-mobile-tools {
    display: inline-flex;
    align-items: center;
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  .btn-login--mobile {
    position: relative;
    z-index: 1;
    margin: 0 20px;
    white-space: nowrap;
  }
}

.site__header--menu-active {
  @include media-breakpoint-down(lg) {
    &::after {
      background-color: $wood-bark;
    }
    .site {
      &__header {
        &-logo path {
          fill: $white;
        }
        &-logo--only-closed {
          display: none;
        }

        &-mobile-top-links {
          display: flex;
        }
      }

      &__navigation {
        transform: translateY(0%);
        opacity: 1;
        transition-delay: 0.1s;
      }

      &__menu-toggle-mobile {
        background-color: $wood-bark;

        .site__menu-toggle-mobile-wrapper {
          @include active-menu-toggle();
        }
      }
    }
    + .site__content {
      filter: blur(4px) grayscale(1);
    }
    .btn-login--only-closed {
      display: none;
    }
  }

  .site {
    &__menu-toggle-mobile {
      background-color: $wood-bark;

      .site__menu-toggle-mobile-wrapper {
        @include active-menu-toggle();
      }
    }
  }
}

.site__header--scrolling {
  background-color: $sand;
  box-shadow: 0 0 6px 2px rgba($brown, 0.2);

  .site__navigation-upper {
    @include media-breakpoint-up(xl) {
      height: 0;
      padding: 0 25px;
      opacity: 0;
    }
  }
}

.site__header--hidden {
  box-shadow: 0 0 6px 2px rgba($brown, 0);
  transform: translateY(-100%);
}

.site__navigation {
  @include media-breakpoint-down(lg) {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    text-align: center;
    background-color: $sand;
    box-shadow: 0 0 14px 2px rgba($wood-bark, 0.4);
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity 0.2s, transform 0.2s;
  }
  @include media-breakpoint-up(xl) {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    align-items: flex-end;
  }

  &-upper {
    display: flex;
    flex-flow: column nowrap;
    order: 2;
    @include media-breakpoint-up(xl) {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      order: initial;
      height: 55px; // necessary for animation
      padding: 8px 25px;
      //overflow: hidden;
      transition: height 0.2s, padding 0.2s, opacity 0.2s;
    }
  }

  &-upper-row {
    margin: 0;
    @include media-breakpoint-up(xl) {
      flex-flow: row nowrap;
      justify-content: center;
      max-width: 420px;
      margin-right: 10px;
    }
  }
  &-upper-row--centered {
    justify-content: center;
  }

  &-upper-link {
    color: $text-color-light;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;

    &:hover {
      color: darken($light-brown, 15%);
      text-decoration: none;
    }
  }

  &-upper-link--chat {
    text-decoration: underline;
    background-color: transparent;
    border: 0;
  }

  &-upper-link--inactive {
    font-weight: $font-weight-normal;

    &:hover {
      text-decoration: underline;
    }
  }

  &-upper-icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    vertical-align: sub;
  }

  &-mobile-buttons {
    display: flex;
    justify-content: space-around;
    order: 2;
    padding: 20px 30px;
    background-color: $white;
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &-mobile-login {
    color: $text-color-light;
  }

  &-main-link {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  &-main-link--inactive {
    display: none;
  }

  &-language-switcher {
    flex-basis: 100%;
    margin-top: 30px;

    @include media-breakpoint-up(xl) {
      flex-basis: initial;
      margin-top: 0;
    }

    .language-switcher {
      @include media-breakpoint-down(lg) {
        border: 0;
      }

      &:hover {
        @include media-breakpoint-down(lg) {
          background-color: transparent;
          border: 0;
        }
      }
    }

    .language-switcher__label,
    .language-switcher__caret,
    .language-switcher__globe {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .language-switcher__list {
      @include media-breakpoint-down(lg) {
        position: relative;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: space-evenly;
        width: 100%;
        max-width: 100%;
        margin-bottom: 45px;
      }
      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(4, 1fr);
      }

      @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .language-switcher__item {
      @include media-breakpoint-down(lg) {
        width: initial;
      }
    }
  }
  &-language-switcher--redesigned {
    margin-top: 0;
    background-color: $white;

    @include media-breakpoint-up(xl) {
      background-color: transparent;
    }

    .language-switcher {
      &__label,
      &__globe {
        @include media-breakpoint-down(lg) {
          display: inline-block;
        }
      }

      &__button {
        width: calc(100% + #{$grid-gutter-width});
        margin: 0 calc(-#{$grid-gutter-width} / 2);
        padding: 16px 14px;
        text-align: left;
        background-color: $sand;
        @include media-breakpoint-up(xl) {
          width: auto;
          margin: 0;
          padding: 0;
          background-color: transparent;
        }
      }

      &__list {
        @include media-breakpoint-down(lg) {
          display: none;
          width: 100%;
          margin-bottom: 16px;
          background-color: transparent;
        }
      }
      &__list--active {
        @include media-breakpoint-down(lg) {
          display: block;
        }
      }
    }

    .language-switcher--active {
      .language-switcher {
        &__button {
          color: $white;
          background-color: $secondary;
          @include media-breakpoint-up(xl) {
            color: inherit;
            background-color: transparent;
          }
        }
      }
      .site__header-nav-icon {
        transform: rotate(-180deg);
      }
    }
  }

  .btn-login {
    display: none;
    @include media-breakpoint-up(xl) {
      display: inline-block;
    }
  }
}

.site__header-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.site__menu-toggle-mobile {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 11px;
  color: $white;
  background-color: $primary;
  border-radius: 7px;
  cursor: pointer;
  @include media-breakpoint-up(xl) {
    display: none;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;

    span {
      display: block;
      width: 24px;
      height: 2px;
      margin-bottom: 6px;
      background-color: $white;
      border-radius: 71px;
      transition: 0.3s;

      &:last-of-type {
        width: 17px;
        margin-bottom: 0;
      }
    }
  }
}
