.prebooking-modal {
  &--absolute {
    position: absolute;
  }
  .modal-dialog {
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }
  .modal-content {
    height: 100%;
    border-radius: 0;
    @include media-breakpoint-big-tablet {
      padding-left: 50px;
    }
  }
  .modal {
    &__close {
      background-color: $white;
      color: $black;
    }
    &__title {
      margin-top: 30px;
      color: $secondary;
    }
  }
  &__therapist-prebooking-list {
    margin-bottom: 20px;
  }
  &__therapist-prebooking-select {
    .react-select {
      z-index: 2;
    }
  }
  &__icon-text {
    margin: 1rem 0;
  }
  .custom-switch {
    &__text {
      display: flex;
      justify-content: flex-start;
      max-width: 240px;
      margin-right: auto;
      color: $text-color-light;
    }
  }
  .without-note {
    .text-area-hide {
      display: none;
    }
  }
}
