.subscription {
  &__list-item {
    display: flex;
    @include media-breakpoint-tablet() {
      display: block;
    }
    .h3 {
      font-size: 20px;
    }
  }

  &__list-point {
    display: inline-flex;
    background: $body-bg;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    margin: 0 30px 30px 0;
  }

  &__card {
    position: relative;
    background: transparent;
    box-shadow: 0 4px 40px rgba(211, 155, 123, 0.2);
    .btn {
      box-shadow: none;
    }

    &--header {
      background: $light-grey;
      &:after {
        content: '';
        width: 100%;
        max-width: 643px;
        height: 19px;
        background: $white;
        position: absolute;
        left: 0;
        bottom: 0;
        transform: scaleY(-1);
        /* use https://wave.novoselski.net/ */
        clip-path: polygon(
          100% 0%,
          0% 0%,
          0% 93.75%,
          1% 93.65%,
          2% 93.34%,
          3% 92.82%,
          4% 92.1%,
          5% 91.18%,
          6% 90.08%,
          7% 88.78%,
          8% 87.31%,
          9% 85.67%,
          10% 83.87%,
          11% 81.92%,
          12% 79.84%,
          13% 77.63%,
          14% 75.31%,
          15% 72.9%,
          16% 70.4%,
          17% 67.84%,
          18% 65.23%,
          19% 62.58%,
          20% 59.91%,
          21% 57.24%,
          22% 54.58%,
          23% 51.95%,
          24% 49.37%,
          25% 46.84%,
          26% 44.39%,
          27% 42.03%,
          28% 39.78%,
          29% 37.65%,
          30% 35.64%,
          31% 33.78%,
          32% 32.08%,
          33% 30.54%,
          34% 29.17%,
          35% 27.99%,
          36% 26.99%,
          37% 26.2%,
          38% 25.6%,
          39% 25.2%,
          40% 25.02%,
          41% 25.04%,
          42% 25.27%,
          43% 25.7%,
          44% 26.34%,
          45% 27.17%,
          46% 28.21%,
          47% 29.43%,
          48% 30.83%,
          49% 32.4%,
          50% 34.14%,
          51% 36.03%,
          52% 38.06%,
          53% 40.22%,
          54% 42.49%,
          55% 44.87%,
          56% 47.34%,
          57% 49.87%,
          58% 52.47%,
          59% 55.11%,
          60% 57.77%,
          61% 60.44%,
          62% 63.11%,
          63% 65.75%,
          64% 68.35%,
          65% 70.9%,
          66% 73.39%,
          67% 75.78%,
          68% 78.08%,
          69% 80.26%,
          70% 82.32%,
          71% 84.24%,
          72% 86.01%,
          73% 87.62%,
          74% 89.05%,
          75% 90.31%,
          76% 91.38%,
          77% 92.26%,
          78% 92.94%,
          79% 93.41%,
          80% 93.68%,
          81% 93.75%,
          82% 93.6%,
          83% 93.25%,
          84% 92.69%,
          85% 91.93%,
          86% 90.98%,
          87% 89.83%,
          88% 88.5%,
          89% 87%,
          90% 85.33%,
          91% 83.5%,
          92% 81.52%,
          93% 79.41%,
          94% 77.18%,
          95% 74.84%,
          96% 72.41%,
          97% 69.9%,
          98% 67.33%,
          99% 64.71%,
          100% 62.05%
        );
        @include media-breakpoint-big-tablet() {
          max-width: 225px;
          transform: scaleY(-1);
          clip-path: polygon(
            100% 0%,
            0% 0%,
            0% 33.33%,
            1% 33.4%,
            2% 33.59%,
            3% 33.9%,
            4% 34.35%,
            5% 34.91%,
            6% 35.59%,
            7% 36.39%,
            8% 37.29%,
            9% 38.3%,
            10% 39.41%,
            11% 40.61%,
            12% 41.9%,
            13% 43.27%,
            14% 44.7%,
            15% 46.2%,
            16% 47.75%,
            17% 49.35%,
            18% 50.98%,
            19% 52.64%,
            20% 54.32%,
            21% 56%,
            22% 57.68%,
            23% 59.35%,
            24% 61%,
            25% 62.61%,
            26% 64.19%,
            27% 65.71%,
            28% 67.18%,
            29% 68.58%,
            30% 69.9%,
            31% 71.15%,
            32% 72.3%,
            33% 73.36%,
            34% 74.31%,
            35% 75.16%,
            36% 75.9%,
            37% 76.52%,
            38% 77.01%,
            39% 77.39%,
            40% 77.64%,
            41% 77.76%,
            42% 77.76%,
            43% 77.63%,
            44% 77.37%,
            45% 76.99%,
            46% 76.48%,
            47% 75.86%,
            48% 75.11%,
            49% 74.26%,
            50% 73.3%,
            51% 72.23%,
            52% 71.07%,
            53% 69.82%,
            54% 68.49%,
            55% 67.09%,
            56% 65.62%,
            57% 64.09%,
            58% 62.51%,
            59% 60.9%,
            60% 59.25%,
            61% 57.58%,
            62% 55.9%,
            63% 54.22%,
            64% 52.54%,
            65% 50.88%,
            66% 49.25%,
            67% 47.66%,
            68% 46.11%,
            69% 44.61%,
            70% 43.18%,
            71% 41.82%,
            72% 40.54%,
            73% 39.34%,
            74% 38.24%,
            75% 37.23%,
            76% 36.33%,
            77% 35.54%,
            78% 34.87%,
            79% 34.31%,
            80% 33.88%,
            81% 33.57%,
            82% 33.39%,
            83% 33.33%,
            84% 33.41%,
            85% 33.6%,
            86% 33.93%,
            87% 34.38%,
            88% 34.95%,
            89% 35.64%,
            90% 36.44%,
            91% 37.35%,
            92% 38.37%,
            93% 39.48%,
            94% 40.69%,
            95% 41.98%,
            96% 43.35%,
            97% 44.79%,
            98% 46.3%,
            99% 47.85%,
            100% 49.45%
          );
        }
      }
    }
  }
}
