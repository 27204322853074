.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1020; // sticky
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba($secondary, 0.8);
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.loader--button {
  position: relative;
  margin-right: 12px;
  background-color: transparent;
  .loader {
    &__spinner,
    &__spinner::after {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    &__spinner {
      border: 2px solid rgba($white, 1);
      border-left-color: $primary;
      transform: translateZ(0);
      animation: spin 1.1s infinite linear;
    }
  }
}

.loader--section {
  position: relative;
  margin-top: 25px;
  background-color: transparent;
  .loader {
    &__spinner,
    &__spinner::after {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    &__spinner {
      border: 2px solid rgba($primary, 0.2);
      border-left-color: $primary;
      transform: translateZ(0);
      animation: spin 1.1s infinite linear;
    }
  }
}

.loader--section-large {
  position: relative;
  margin-top: 25px;
  background-color: transparent;
  .loader {
    &__spinner,
    &__spinner::after {
      width: 75px;
      height: 75px;
      border-radius: 100%;
    }
    &__spinner {
      border-top: 4px solid $primary;
      border-right: 4px solid transparent;
      animation: 1s spin linear infinite;
    }
  }
}

.loader--basic {
  .loader {
    &__spinner {
      width: 75px;
      height: 75px;
      border-top: 4px solid $primary;
      border-right: 4px solid transparent;
      border-radius: 100%;
      animation: 1s spin linear infinite;
    }
    &__message {
      font-weight: bold;
      margin-top: 20px;
      color: $white;
    }
  }
}

.loader--light-gray {
  background-color: rgba($light-body-bg, 0.8);
  &__message {
    color: $black;
  }
}

.loader--kitty {
  .loader {
    &__spinner {
      width: 340px;
      height: 175px;
      background-image: url('/images/loader.webp');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.loader--complex {
  .loader {
    &__spinner {
      position: relative;
      display: block;
      width: 125px;
      height: 125px;
      border: 4px solid transparent;
      border-top: 4px solid darken($primary, 10%);
      border-radius: 100%;
      animation: spin 2s linear infinite;
    }
    &__spinner::before,
    &__spinner::after {
      position: absolute;
      border: 4px solid transparent;
      border-radius: 100%;
      content: '';
    }
    &__spinner::before {
      top: 5px;
      right: 5px;
      bottom: 5px;
      left: 5px;
      border-top-color: $primary;
      animation: spin 3.5s linear infinite;
    }
    &__spinner::after {
      top: 15px;
      right: 15px;
      bottom: 15px;
      left: 15px;
      border-top-color: lighten($primary, 10%);
      animation: spin 1.75s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: $secondary;
  color: $secondary;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: $secondary;
  color: $secondary;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: $secondary;
  color: $secondary;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: $petroleum-light;
  }
  100% {
    background-color: $secondary;
  }
}

// loading therapists

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes fadeOutUp1 {
  0% {
    opacity: 0.3;
    transform: translateY(0);
  }
  33%,
  100% {
    transform: translateY(-80px);
  }
  33% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOutUp2 {
  0% {
    opacity: 0.5;
    transform: translateY(0);
  }
  33%,
  100% {
    transform: translateY(-80px);
  }
  33% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes fadeOutUp3 {
  0% {
    opacity: 0.1;
    transform: translateY(0);
  }
  33% {
    opacity: 0.5;
  }
  33%,
  100% {
    transform: translateY(-80px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutUp4 {
  0% {
    opacity: 0.1;
    transform: translateY(0);
  }
  33%,
  100% {
    transform: translateY(-80px);
  }
  33% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.3;
  }
}

.fadeOutUp1 {
  animation-name: fadeOutUp1;
}

.fadeOutUp2 {
  animation-name: fadeOutUp2;
}

.fadeOutUp3 {
  animation-name: fadeOutUp3;
}
.fadeOutUp4 {
  animation-name: fadeOutUp4;
}

.transition-delay-250 {
  transition-delay: 0.25s, 0s;
}
