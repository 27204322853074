.upload {
  &--chat {
    position: absolute;
    bottom: 70px;
    margin-bottom: 10px;
    padding: 0 20px;
    width: 100%;
  }

  .uppy-Root {
    .uppy-Dashboard-inner {
      height: 300px;
      background-color: $light-body-bg;
      border: none;
      width: 100%;

      .uppy-Dashboard-innerWrap {
        height: 100%;
        background-color: $light-body-bg;

        .uppy-Dashboard-AddFiles-title {
          color: $text-color-light;
          button {
            color: $primary;
            text-decoration: underline;
            transition: 0.2s;
            &:hover {
              text-decoration: none;
              border-bottom: 0;
            }
            &:focus {
              border-bottom: none;
            }
          }
        }
        .uppy-DashboardContent-bar {
          background-color: $light-body-bg;
          border-bottom: 2px solid $light;

          .uppy-DashboardContent-back {
            color: $black;
          }
        }
      }
    }
  }
}
