.session-box {
  display: none;
  margin: $session-box-margin;
  padding: $session-box-padding;
  font-size: $session-box-font-size;
  white-space: pre-line;
  background-color: $session-box-bg;
  border-radius: $session-box-border-radius;
  &__last-item {
    margin-bottom: 0;
  }
  @media screen and (min-height: 785px) {
    display: block;
  }
}
