.list-icon {
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  &__item {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    padding: 25px 0;
    font-size: 16px;
    line-height: 24px;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background: url('/images/svg/styleElements/dots2-horizontal.svg') left center repeat-x;
      content: '';
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
  &__icon {
    width: 28px;
    height: 28px;
    margin-right: 29px;
    color: $secondary;
  }
  &__icon-wrapper {
    display: flex;
    align-items: center;
  }
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  h4 {
    margin-bottom: 17px;
    color: $secondary;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  &--no-divider {
    .list-icon__item {
      &::after {
        display: none;
      }
    }
  }
}
