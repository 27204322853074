.phone {
  &__support {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    @include media-breakpoint-laptop {
      justify-content: flex-start;
    }
    span {
      font-size: $font-size-base;
    }
    strong {
      position: relative;
      bottom: 3px;
      text-transform: capitalize;
    }
  }
}

.phone__support--icon {
  align-items: center;
  .icon {
    margin-right: 10px;
    width: 14px;
    min-width: 14px;
    height: 14px;
  }
  .phone__text {
    margin-right: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }
  strong {
    font-size: 24px;
  }
}

.phone__support--diagnosis {
  justify-content: center;
}

.phone__support--diagnosis-test {
  margin-top: 95px;

  @include media-breakpoint-tablet {
    margin: 0;
  }
}

.phone__support--diagnosis-test-plain {
  margin-top: 45px;

  @include media-breakpoint-tablet {
    margin: 0;
  }
}
