.academy {
  &__content {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $secondary;
      margin: 23px 0 15px;
      font-weight: 700;

      @include media-breakpoint-big-tablet() {
        margin: 52px 0 25px;
      }
    }

    h3 {
      font-weight: 400;
    }

    p {
      font-size: $font-size-md;
      line-height: 30px;
    }

    li {
      line-height: 30px;
    }

    ol {
      list-style: none;
      counter-reset: li-counter;
      margin: 30px 0 30px;
      padding: 0;
      position: relative;

      > li {
        padding: 0;
        counter-increment: li-counter;
        margin: 24px 0;
        padding-left: 46px;

        &:before {
          content: counter(li-counter);
          color: $secondary;
          font-size: 14px;
          font-weight: bold;
          width: 26px;
          height: 26px;
          border: 2px solid $secondary;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 1px;
          margin-right: 20px;
          position: absolute;
          left: 0;
        }
      }

      ol {
        padding-left: 32px;
        margin-top: 20px;

        li {
          padding: 0;
          margin: 12px 0;

          &:before {
            border: none;
          }
        }
      }
    }

    .faq-content {
      font-size: 14px;
      line-height: 30px;
      p, > span {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }

  &__nav-button {
    position: fixed;
    top: 82px;
    z-index: 100;

    @include media-breakpoint-big-tablet() {
      top: 0;
    }

    &--isWebview {
      top: 0;
      left: 0;
    }
  }

  &__nav {
    display: flex;
    position: fixed;
    top: 156px;
    background-color: $light;
    width: 100%;
    padding: 0 18px 15px;
    z-index: -1;
    transform: translateX(-100%);
    transition: transform 0.2s;
    overflow-y: scroll;

    &--open {
      z-index: 100;
      transform: translateX(0);
      transition: transform 0.2s;
      max-height: calc(100% - 150px);
      overflow: auto;
    }

    &--isWebview {
      top: 76px;
      left: 0;
    }

    @include media-breakpoint-big-tablet() {
      top: 82px;
      transform: none;
      max-height: 100%;
    }

    @include media-breakpoint-laptop() {
      position: initial;
      z-index: 0;
      transform: translateX(0);
      padding: 28px 28px 36px;
    }
  }
}

.academy-nav-item:last-child {
  margin-bottom: 30px;
}
