/* ===================================================
   ==== Component: Global style for  TherapistCalendarToolbar & ClientCalendarToolbar ====
   =================================================== */
.calendar-toolbar {
  &__wrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid $body-bg;
    &:not(&--active) {
      align-items: center;
      justify-content: space-between;
    }
    &--client {
      flex-direction: column;
      align-items: flex-start !important;
      @include media-breakpoint-tablet() {
        flex-direction: row;
        align-items: center !important;
      }
    }
  }
  &__toolbar {
    > .MuiToolbar-root {
      border-bottom: 0;
    }


  }

  &__toolbar {
    .MuiToolbar-root {
      > div {
        display: flex;
        align-items: center;
      }
    }
  }
}

/* ===================================================
   ==== Component: ClientCalendarToolbar ====
   =================================================== */
.calendar-toolbar__wrapper--client {
  .calendar-toolbar__toolbar {
    display: flex;
    align-items: center;
    width: 100%;
    > div:nth-of-type(1) {
      display: flex;
      padding: 0;
      @include media-breakpoint-tablet() {
        padding: 0 16px;
      }
      > button {
        display: none;
        @include media-breakpoint-tablet() {
          display: block;
        }
      }
      > div:nth-of-type(1) {
        display: flex;
      }
    }
    .filter__ {
      padding-right: 10px;
    }
  }
}

/* ===================================================
   ==== Component: TherapistCalendarToolbar ====
   =================================================== */

.calendar-toolbar {
  &__prebooking-content {
    z-index: 2; // overshoot scheduler shadow

    @include media-breakpoint-tablet() {
      padding-right: 16px;
    }
  }
  &__prebooking-button {
    max-width: 72px;
    color: $text-color-light;
    font-weight: bold;
    font-size: $font-size-sm;
    font-style: normal;
    line-height: $font-size-sm;
    letter-spacing: 0.02em;
    text-align: center;
    background: transparent;
    border: 0;
    text-decoration-line: underline;
    @include media-breakpoint-tablet() {
      max-width: 100%;
      padding: 17px 16px;
      color: $white;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      vertical-align: middle;
      background-color: $primary;
      border-color: $primary;
      border-radius: 99px;
      transition: 0.2s;
      text-decoration-line: none;
      &:hover {
        color: $white;
        background-color: $brown;
        border-color: $brown;
        box-shadow: none;
      }
    }
    &:focus,
    &:active {
      outline: none;
    }
  }
  &__close-prebooking {
    padding: 0;
  }


}

// Active Prebooking
.calendar-toolbar__wrapper--active {
  flex-direction: column-reverse;
  .calendar-toolbar {
    &__toolbar {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      > .services-select {
        @media screen and (max-width: 768px) {
          flex-basis: initial;
          max-width: none;
          width: 100%;
          margin-bottom: 4px;


          &.react-select .react-select__control {
            width: 100%;
            margin: 0 15px;
          }
        }
      }
    }
  }

  @include media-breakpoint-laptop() {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $success;
    .calendar-toolbar {
      &__prebooking-content {
        width: auto;
        padding: 0;
        .toolbar-info__wrapper {
          max-width: 400px;
          svg {
            display: block;
            path {
              fill: $success;
            }
          }
        }
        .toolbar-info__text {
          margin-left: 20px;
        }
      }
      &__toolbar {
        .MuiToolbar-root {
          > button {
            color: $white;
            border-color: $white;
          }
          > div {
            button {
              color: $white;
            }
          }
        }
      }
    }
  }

  .calendar-toolbar {
    &__prebooking-content {
      display: flex;
      justify-content: space-between;
      margin-right: 0;
      padding: 12px 16px;
      background-color: $success;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      @include media-breakpoint-tablet() {
        justify-content: space-between;
      }

      @include media-breakpoint-desktop() {
        justify-content: space-around;
      }

      .toolbar-info__wrapper {
        padding: 0;
        color: $white;
        svg {
          display: none;
        }
      }
      .toolbar-info__text {
        margin-left: 0;
      }
    }
    &__prebooking-info-wrapper {
      display: flex;
      align-items: center;
      color: $white;
      span {
        display: block;
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;

        @include media-breakpoint-laptop() {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    &__prebooking-content-buttons {
      display: flex;
    }
    &__prebooking-continue {
      position: fixed;
      bottom: 0;
      left: 50%;
      width: 100%;
      padding: 20px 0;
      text-align: center;
      background: linear-gradient(180deg, #fdf7f1 -29.68%, rgba(255, 255, 255, 0) -29.68%, #fdf7f1 74.59%);
      transform: translate(-50%, 0);
      @include media-breakpoint-tablet() {
        position: relative;
        left: 0;
        width: auto;
        padding: 0;
        background: transparent;
        transform: none;
        .btn {
          padding: 12px 16px;
          font-size: $font-size-sm;
        }
        &--call {
          transform: none;
        }
      }
      &--call {
        padding: 0 0 4px;
        background: transparent;
      }
    }
  }
}
