@mixin checkbox-button-active() {
  color: $white;
  background-color: $primary;
  .custom-checkbox__icon {
    color: $white;
  }
}

.form-control {
  border-color: $light-brown-trans;
}

.custom-checkbox {
  label {
    line-height: normal;
  }

  .signup & {
    input[type='checkbox'] {
      display: block;
    }
  }
}

.custom-checkbox--button {
  max-width: 480px;
  margin: 20px auto;
  padding-left: 0;
  .custom-checkbox {
    &__icon {
      margin-left: 15px;
      color: $primary;
      transition: color 0.2s;
    }
  }
  .custom-control-label {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    line-height: 1.4;
    background-color: $white;
    border-radius: 75px;
    box-shadow: 0 7px 30px rgba(211, 155, 123, 0.3);
    cursor: pointer;
    transition:
      background-color 0.2s,
      color 0.2s;
    @include media-breakpoint-tablet {
      padding: 25px 50px;
    }
    &:hover {
      @include checkbox-button-active();
    }
    &::before,
    &::after {
      display: none;
    }
  }

  label {
    line-height: normal;
  }
}

.custom-checkbox--tile {
  padding-left: 0;
  .custom-control-input {
    &:checked {
      + .custom-control-label {
        color: $white;
        background-color: $primary;
        &::before {
          background-color: $brown;
          border-color: $white;
          border-width: 4px;
        }
      }
    }
  }

  .custom-control-label {
    width: calc(100% - 30px); // - margin
    margin: 15px;
    padding: 32px;
    font-weight: 700;
    font-size: 20px;
    background-color: $white;
    border-radius: $border-radius-lg;
    cursor: pointer;
    transition:
      background-color 0.2s,
      color 0.2s;
    &::before {
      position: static !important;
      display: inline-block;
      width: 30px !important;
      height: 30px !important;
      margin-right: 25px;
      vertical-align: middle;
      border-color: $primary !important;
      border-radius: 100% !important;
    }
    &::after {
      display: none;
    }
  }
}

.form-group {
  &__sup {
    top: auto;
    right: -5px;
    bottom: -8px;
    color: $primary;
    font-size: 40px;
  }
}

/* ===================================================
   ==== Modifier: Customize forms  =======================
   =================================================== */
.forms {
  &__therapist-settings {
    align-items: center;
    .form-group {
      @include media-breakpoint-tablet() {
        margin-bottom: 40px;
      }
    }
    .btn {
      margin-bottom: 10px;
    }
  }
}

/* ===================================================
   ==== Modifier: Switch  =======================
   =================================================== */

.custom-control {
  padding-left: 0;
  align-items: center;
  .on {
    &:after,
    &:before {
      display: none;
    }
  }

  &.custom-switch {
    z-index: 0;
    &--row {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .custom-control-label {
      color: $petroleum-light;

      &.disabled {
        color: $light-brown-trans;
      }
      &.active {
        color: $secondary;
        &.disabled {
          color: $light-brown-trans;
        }
      }
    }
    &--large-switcher {
      display: flex;
      padding-left: 0;
      .custom-control-input {
        &:checked {
          + .custom-control-label {
            &::before {
              background-color: $secondary;
            }
            &::after {
              background-color: white;
              transform: translateX(calc(100% + 7px));
            }
          }
        }
      }
      .custom-control-label {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding-top: 10px;

        &.off {
          padding-left: 16px;
        }
        &.on {
          padding-right: 50px;
        }
        &:before {
          cursor: pointer;
          border-color: transparent;
          padding: 12px 26px;
          border-radius: 15px;
          flex-shrink: 0;
          box-shadow: none;
        }
        &:after {
          cursor: pointer;
          background-color: white;
          padding: 10px;
        }

        @include media-breakpoint-tablet() {
          font-size: 14px;

          &.off {
            padding-left: 30px;
          }
          &.on {
            padding-right: 54px;
          }
          &:before {
            padding: 14px 30px;
          }
          &:after {
            padding: 12px;
          }
        }
      }
    }

    &--on-checked {
      .on {
        color: $text-color-light;
      }
      .off.custom-control-label {
        color: $light-brown-trans;
      }
      .custom-control-label.active {
        color: $text-color-light;
      }
    }

    .disabled {
      &:before,
      &:after {
        cursor: default;
        background-color: $light-brown-trans;
      }
      &:after {
        background-color: white;
      }
    }
  }
  .custom-switch {
    &__text {
      color: #473134;
      font-size: $font-size-sm;
      font-weight: bold;
    }
  }
}

/* ===================================================
   ==== Component: Datetime  =======================
   =================================================== */
.form-group {
  .form-control.datetime {
    padding: 12px;
    &::-webkit-calendar-picker-indicator {
      background-color: $light;
      border-radius: $border-radius;
      padding: 8px;
    }
  }
}

/* ===================================================
   ==== Component: DiagnosisServicesStep  =======================
   =================================================== */
.diagnosis-services-step {
  &__services-list {
    .custom-control-label {
      font-weight: bold;
      span:first-letter {
        text-transform: uppercase;
      }
    }

    .custom-control {
      display: block;
    }
  }
  &__show-more {
    display: flex;
    justify-content: center;
    margin: 30px 0;

    button {
      color: $text-color-light;
      font-size: 16px;
    }
  }
  &__list {
    .custom-control-label > span {
      font-weight: bold;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.error {
  &__field {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $error;
  }
}

.file {
  &__field {
    input {
      visibility: hidden;
      height: 0;
    }
  }

  &__box {
    position: relative;
    padding: 45px 20px;
    background-color: $body-bg;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $text-color-light;

    &:before {
      content: ' ';
      position: absolute;
      top: calc(1px * -1);
      right: calc(1px * -1);
      bottom: calc(1px * -1);
      left: calc(1px * -1);
      z-index: -1;
      border-radius: 8px;
      background-image: repeating-linear-gradient(135deg, transparent 0 3px, $body-bg 3px 5px);
      background-color: $primary;
    }

    &:after {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: $primary;
      opacity: 0.24;
      border-radius: 8px;
    }
  }

  .file__field {
    cursor: default;
  }

  &__pdf {
    background: #e2514a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 100%;
  }
}
