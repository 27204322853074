.card {
  margin: 10px 0;
  padding: 25px 35px;
  border-radius: $border-radius-lg;
  @include media-breakpoint-tablet() {
    padding: 50px;
  }
  &--small {
    @include media-breakpoint-tablet() {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  &__content {
    max-width: 350px;
  }
  &__title {
    margin-bottom: 30px;
  }
  &__icon-text {
    margin-top: 25px;
    font-size: $font-size-sm;
  }
  &__text {
    font-size: $font-size-base;
  }
  &__button-wrapper {
    text-align: center;
    @include media-breakpoint-tablet() {
      text-align: left;
    }
  }
  &__button {
    margin-top: 30px;
  }
}
