.chat {
  display: none;
  &__content {
    position: fixed;
    background: $white;
    z-index: 20;
    bottom: 0;
    height: calc(100% - 60px);
    width: 100%;
    border-radius: 17px 17px 0 0;
    padding-top: 50px;

    @include media-breakpoint-laptop() {
      position: relative;
      border-radius: 0;
      width: 30%;
      min-width: 530px;
      height: 100%;
    }

    .modal__close {
      cursor: pointer;
      background-color: transparent;
      color: $black;
    }
  }

  .call--with-chat {
    .chat {
      display: block;
    }
  }

  /* ==========================================================================
  Chat : Messages
========================================================================== */

  &__no-messages {
    display: block;
    text-align: center;
    color: $text-color-light;
  }

  &__messages-wrapper {
    // background: $white;
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px 20px;
    height: 100%;
    overflow: auto;
  }

  &__messages {
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-grow: 1;

    &::-webkit-scrollbar {
      width: 0;
    }

    .loader.loader--section {
      height: auto;
      margin-top: 0;
    }
  }

  &__message-wrapper {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    margin-right: 10px;
  }

  &__message-info {
    margin-left: 6px;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 12px;
    color: $text-color;
  }

  &__message-text {
    padding: 14px 20px;
    background-color: $chat-bg-color;
    color: $chat-text-color;
    border-radius: 12px 12px 12px 0;
  }

  &__message-image-wrapper {
    width: 52px;
    height: 54px;
    border-radius: 10px;
    margin-right: 10px;
    img {
      height: 100%;
      width: 100%;
      border-radius: 10px;
    }
  }

  &__message-image-wrapper--empty {
    padding-right: 52px;
  }

  // Message style from author
  &__message-wrapper--author {
    justify-content: flex-end;
    .chat__message-info {
      margin-left: 0;
      margin-right: 6px;
    }
  }

  &__message-text--author {
    background-color: $body-bg;
    color: $text-color;
    border-radius: 12px 12px 0 12px;
  }

  /* ==========================================================================
  Chat : Files message
========================================================================== */
  &__file-button {
    background-color: $white;
    color: $primary;
    box-shadow: none;
    padding: 8px 10px;
    font-size: 12px;
    border-radius: 8px;
    svg {
      margin: 0 4px 4px 0;
    }
    &.btn-loading {
      padding: 8px 10px 8px 50px;
    }
  }
}
