$petrol-400: #235963;
$petrol-500: #0B4752;
$peach-100: #FFDED2;
$peach-400: #F69774;
$peach-500: #E47B53;
$peach-600: #E55823;
$peach-800: #C73000;
$sand-400: #EBE7E2;
$sand-500: #F3F0EB;
$sand-600: #DED8CE;
$sand-700: #BAB1A1;
$pink-500: #E6CFE3;
$blue-500: #C5E7E8;
$yellow-400: #FAFFB1;
$yellow-500: #EFF690;
$yellow-600: #F5FF67;
$yellow-800: #F1BF42;

:root {--color-petrol-400: #235963;
--color-petrol-500: #0B4752;
--color-peach-100: #FFDED2;
--color-peach-400: #F69774;
--color-peach-500: #E47B53;
--color-peach-600: #E55823;
--color-peach-800: #C73000;
--color-sand-400: #EBE7E2;
--color-sand-500: #F3F0EB;
--color-sand-600: #DED8CE;
--color-sand-700: #BAB1A1;
--color-pink-500: #E6CFE3;
--color-blue-500: #C5E7E8;
--color-yellow-400: #FAFFB1;
--color-yellow-500: #EFF690;
--color-yellow-600: #F5FF67;
--color-yellow-800: #F1BF42;
}
@import "theme-variables-new.scss";
// node_modules
@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("opentype");
    font-display:swap;font-style:normal;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/8738d8/00000000000000007735e611/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/8738d8/00000000000000007735e611/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/8738d8/00000000000000007735e611/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n8&v=3") format("opentype");
    font-display:swap;font-style:normal;font-weight:800;font-stretch:normal;
    }
    
    @font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("opentype");
    font-display:swap;font-style:normal;font-weight:600;font-stretch:normal;
    }
    
    @font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
    font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
    }
    
    @font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("opentype");
    font-display:swap;font-style:italic;font-weight:400;font-stretch:normal;
    }
    
    .tk-proxima-nova { font-family: "proxima-nova",sans-serif; }


@import 'wordpress/style.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';
@import 'mixins';
@import 'base';
@import 'components';
@import 'containers';
@import 'vendors';
