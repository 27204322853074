.voucher {
  &__field {
    .form-control {
      border-color: #f1ebe5;

      &::placeholder {
        color: $text-color-light;
      }
    }
  }
}
