.filter {
  align-items: center;
  justify-content: center;
  &__filter-buttons {
    margin-bottom: 10px;
    @include media-breakpoint-tablet {
      display: flex;
      justify-content: center;
    }
    @include media-breakpoint-desktop {
      margin-bottom: 0;
    }
  }
  &__time-filter-wrapper {
    margin-bottom: 10px;
    text-align: center;
    @include media-breakpoint-desktop {
      margin-bottom: 0;
      text-align: left;
    }
    .btn-light {
      color: $text-color;
      font-weight: 700;
      &:not(:hover) {
        background-color: $light;
        border-color: $light;
      }
    }
    button {
      width: 100%;
      @include media-breakpoint-tablet {
        max-width: 240px;
      }
      @include media-breakpoint-desktop {
        width: auto;
        text-align: left;
      }
    }
  }
  .react-select {
    max-width: 100%;
    @include media-breakpoint-tablet {
      max-width: 240px;
    }
  }
}
