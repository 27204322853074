/* ==========================================================================
Clearfix
========================================================================== */
@mixin clearfix {
  &::before,
  &::after {
    display: table;
    content: ' ';
  }

  &::after {
    clear: both;
  }
}

@mixin active-menu-toggle {
  span:nth-of-type(2) {
    opacity: 0;
  }

  span:nth-of-type(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  span:nth-of-type(3) {
    width: 24px;
    transform: rotate(-45deg) translate(6px, -6px);
  }
}

/* ==========================================================================
Stretch to site Wide
========================================================================== */
@mixin stretch-to-site-width {
  @include media-breakpoint-up(sm) {
    width: #{map-get($grid-breakpoints, 'md')};
    margin: 0 calc(((-#{map-get($grid-breakpoints, 'md')} + #{map-get($container-max-widths, 'sm')}) / 2) + -15px);
  }
  @include media-breakpoint-up(md) {
    width: #{map-get($grid-breakpoints, 'lg')};
    margin: 0 calc(((-#{map-get($grid-breakpoints, 'lg')} + #{map-get($container-max-widths, 'md')}) / 2) + -15px);
  }
  @include media-breakpoint-up(lg) {
    width: #{map-get($grid-breakpoints, 'xl')};
    margin: 0 calc(((-#{map-get($grid-breakpoints, 'xl')} + #{map-get($container-max-widths, 'lg')}) / 2) + -15px);
  }
  @include media-breakpoint-up(xl) {
    width: $site-width;
    margin: 0 calc(((-#{$site-width} + #{map-get($container-max-widths, 'xl')}) / 2) + -15px);
  }
}
