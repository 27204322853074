.afterFeedback {
  margin-top: 100px;
  text-align: center;
}

.afterFeedback {
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  &__button {
    margin: 20px 0;
    @include media-breakpoint-tablet() {
      margin: 20px 30px 50px 0;
    }
  }
}

.feedback-form {
  .custom-control {
    justify-content: center;
  }
}
