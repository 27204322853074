.signup {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $signup-header-height;
    padding: $signup-header-padding;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  &__logo {
    box-sizing: content-box;
    width: $signup-logo-width;
    margin-left: -26px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - #{$signup-header-height + $signup-header-offset});
    padding-bottom: $signup-header-height;
  }
  &__row {
    align-items: flex-start;
  }
  &__title {
    margin-bottom: 15px;
  }
  &__mosaic {
    max-width: 100%;
    height: auto;
  }
  &__benefits {
    margin-bottom: 25px;
    .icon {
      margin-top: 5px;
    }
  }
  &__benefits-item {
    display: flex;
    justify-content: center;
  }
}
