.modal {
  background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
  &-header {
    padding-right: 75px; // space for close
  }
  &__close {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    color: $white;
    line-height: 1;
    background-color: $primary;
    border: 2px solid transparent;
    border-radius: 100%;
    transition: background-color 0.2s;
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-appearance: none;
    &:hover {
      background-color: darken($primary, 10%);
    }
    &:focus {
      outline: none;
    }
  }

  // custom size
  .modal-dialog.modal-xl {
    max-width: 1270px;
    &.modal-dialog-scrollable {
      @media screen and (max-width: 1350px) {
        padding-bottom: 50px;
      }
    }
    @media screen and (max-width: 1350px) {
      padding: 0 20px;
      .modal__close {
        position: fixed;
        top: auto;
        right: 50%;
        bottom: 45px;
        z-index: 1;
        transform: translate(50%, 100%);
      }
    }
  }
}

.modal--video {
  .modal-content {
    max-width: 720px;
  }
  .modal {
    &-header,
    &-body,
    &-footer {
      padding: 0;
    }
    &__close {
      top: auto;
      right: 50%;
      bottom: -20px;
      z-index: 1;
      transform: translate(50%, 100%);
      @include media-breakpoint-up(lg) {
        top: 0;
        right: 0;
        bottom: auto;
        transform: translate(100%, -100%);
      }
    }
  }
  .modal-lg {
    iframe {
      min-height: 450px;
    }
  }
  iframe {
    float: left; // fix for empty space below
    width: 100%;
    min-height: 281px;
    border: 0;
    border-radius: $modal-content-border-radius;
  }
}
