.modal {
  &__close-bg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  &__close {
    z-index: 10;
  }
  &__back {
    border: 0;
    line-height: 34px;
    background-color: inherit;
    display: flex;
    width: auto;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    color: $text-color;
    &:focus {
      outline: none;
    }
    .icon--native {
      margin-right: 15px;
      width: 18px;
      min-width: 18px;
      height: 18px;
    }
  }
  &-header {
    display: flex;
    flex-direction: column;
  }
  &--no-gutters {
    .modal-dialog {
      padding: 0 !important;
      margin: 0;
    }
  }

  .modal-dialog.modal-xl {
    @media screen and (max-width: 1350px) {
      .modal__close {
        position: absolute;
        top: -15px;
        right: 35px;
      }
    }
  }
}

// Discount modal
.discount-modal {
  .dots {
    display: block;
    flex-grow: 10;
    background: radial-gradient(circle, rgba(48, 48, 48, 0.62) 2px, transparent 2px) repeat-x;
    background-size: 20px 20px;
  }
  .modal {
    &-body {
      padding: 0;
      border-radius: 20px;
    }
    &-content {
      overflow: hidden;
      border-radius: 20px;
    }
  }
  .description {
    &__row {
      align-items: center;
    }
    &__voucher-form {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__content {
      padding: 30px 20px;
      @include media-breakpoint-tablet() {
        padding: 45px 55px 20px;
      }
      h2 {
        @media screen and (max-width: 320px) {
          font-size: 21px;
        }
      }
    }

    &__content--voucher {
      @include media-breakpoint-tablet() {
        padding: 100px 55px;
      }
    }

    &__title {
      margin-bottom: 15px;
      @include media-breakpoint-tablet() {
        margin-bottom: 25px;
      }
      @include media-breakpoint-desktop() {
        margin-bottom: 35px;
      }
    }
    &__name {
      &:first-letter {
        text-transform: capitalize;
      }
    }
    &__banner {
      padding: 30px 25px;
      text-align: center;
      background-color: $body-bg;
      border-radius: $border-radius-lg;
      @media screen and (max-width: 320px) {
        padding: 20px 15px;
      }
      @include media-breakpoint-tablet() {
        padding: 30px 40px;
        // text-align: left;
      }
      h2,
      h1,
      p {
        margin-bottom: 0px;
      }
    }
    &__benefits--voucher {
      margin-top: 20px;
    }
    &__old-price {
      margin-bottom: 0;
      color: $text-color-light;
      text-decoration: line-through;
    }
    &__discount {
      margin-bottom: 0;
      font-size: $font-size-base;
    }
    &__time {
      color: $text-color-light;
      font-size: 12px;
      text-transform: uppercase;
    }
    &__payment {
      display: flex;
      justify-content: space-around;
      @include media-breakpoint-tablet() {
        margin-top: 30px;
      }
      &-button {
        width: 100%;
        margin-top: 20px;
      }
    }
    &__code {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      button {
        font-size: 16px;
      }
    }
    &__code--voucher {
      margin-top: 0;
    }
  }
  .info {
    background-color: $light;
    &__content {
      padding: 40px;
      @include media-breakpoint-tablet() {
        padding: 65px 40px;
      }
      .icon-text {
        display: flex;
        align-items: center;
      }
      span {
        font-size: $font-size-sm;
      }
    }
    &__photo {
      max-width: 130px;
      max-height: 160px;
      margin-bottom: 15px;
      overflow: hidden;
      border-radius: 6px;
      @include media-breakpoint-tablet() {
        margin-bottom: 35px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__meeting {
      margin-top: 25px;
      padding-top: 40px;
      font-size: $font-size-base;
      @include media-breakpoint-tablet() {
        margin-top: 35px;
        padding-top: 50px;
      }
      p {
        margin-bottom: 0px;
      }
    }
    &__name {
      margin-bottom: 0;
    }
    &__experience {
      color: $text-color-light;
      font-size: $font-size-base;
    }
  }

  .therapist {
    &__photo {
      max-width: 85px;
      max-height: 99px;
      margin-bottom: 15px;
      overflow: hidden;
      border-radius: 6px;
      @include media-breakpoint-tablet() {
        margin-bottom: 35px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.payment-modal {
  // override fullscreen styles
  .modal-dialog.modal-xl.modal-dialog-scrollable, .modal-dialog.modal-xl.modal-dialog-scrollable {
    padding-bottom: 0;
  }

  .modal-dialog-centered {
    min-height: auto;
  }

  .modal-dialog-scrollable {
    max-height: unset;
  }

  .modal-body, .modal-content {
    border-radius: unset;
  }

  .modal-dialog {
    margin: 0;
  }
}

// specific modals
.feedback-modal {
  h2,
  .lead {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  h2 {
    margin-bottom: 0;
  }
  .lead {
    color: $text-color-light;
  }
  .submit-col {
    text-align: center;
  }
  .form-group {
    max-width: 590px;
    margin-left: auto;
    margin-right: auto;
  }
  .additionalInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}

// specific modal
.calendar-modal {
  overflow-y: auto;
  &__wrapper {
    width: 100%;
  }
  .calendar-box__root--call {
    margin: 40px 0 0 0;
    border-radius: 0;
    height: 100%;
  }
  @include media-breakpoint-laptop() {
    &__wrapper {
      max-width: 1024px;
      margin: 0 auto;
    }
    .calendar-box__root--call {
      height: auto;
      border-radius: $border-radius-lg;
    }
  }
}
