.navigation-item {
  &__btn {
    border-top: $navigation-item-border-width solid $navigation-item-border-color;
    border-bottom: $navigation-item-border-width solid $navigation-item-border-color;
    margin-top: -2px;
    // margin-bottom: -2px;
    width: 100%;
    font-size: $navigation-item-font-size;
    text-align: left;
    text-transform: none;
    border-radius: $navigation-item-border-radius;
    padding: 12px 22px;
    font-weight: 400;
    position: relative;
    @include media-breakpoint-big-tablet {
      padding-right: 12px;
      padding-left: 12px;
    }
    @include media-breakpoint-desktop {
      padding: 12px 22px;
    }
    &:hover {
      border-bottom-color: $navigation-item-active-bg;
      border-top-color: $navigation-item-active-bg;
      background-color: $navigation-item-hover-bg;
      z-index: 10;
    }
    &:focus {
      box-shadow: none;
    }

    &--sub {
      background-color: transparent;
    }
    &--sub-open,
    &--sub-active {
      background-color: $session-box-bg;
    }
    &--sub-open {
      border-bottom: none;
    }
  }
  &__icon {
    margin-right: $navigation-item-icon-offset;
    @include media-breakpoint-big-tablet {
      margin-right: 15px;
    }
    @include media-breakpoint-desktop {
      margin-right: $navigation-item-icon-offset;
    }
  }

  .navigation-item {
    margin-bottom: 4px;

    .navigation-item__btn {
      border-radius: 6px;
      border-width: 0;
      padding: 8px 50px 8px;
    }
  }
}

.navigation-item--active {
  .navigation-item {
    &__btn {
      color: $navigation-item-active-color;
      background-color: $navigation-item-active-bg;
      border-bottom-color: $navigation-item-active-bg;
      border-top-color: $navigation-item-active-bg;
      z-index: 10;
      font-weight: bold;
    }
  }
}

.anchor {
  &__item-active {
    > div {
      font-weight: bold;
    }
  }

  &__section {
    display: block;
    position: relative;
    top: -160px;
    visibility: hidden;

    @include media-breakpoint-laptop() {
      top: 0;
    }
  }
}
