.user-input {
  padding-top: 10px;
  background: white;

  &__wrapper {
    position: relative;
    width: 100%;
    display: flex;

    justify-content: center;
    // padding-top: 10px;
    background: $white;
  }

  /* ==========================================================================
  Inputs styles
========================================================================== */
  &__add-messages-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border: 2px solid $light;
    box-sizing: border-box;
    border-radius: 32px;
    width: 100%;
  }
  &__input {
    font-family: proxima-nova, sans-serif !important;
    border: none;
    font-size: $font-size-sm;
    line-height: $font-size-base;
    color: $text-color;
    padding: 20px 0;
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  &__input-autosize {
    font-family: proxima-nova, sans-serif !important;
    font-size: $font-size-sm;
    line-height: $font-size-lg;
    color: $text-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 32px;
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  &__upload-button {
    background: $white;
    border: none;
    color: $primary;
    margin-left: 10px;
    margin-right: 10px;
    height: 60px;
    align-self: end;
    transition: color 0.2s;
    &:focus {
      outline: none;
    }
  }

  &__upload-button--cross {
    color: $black;
  }

  /* ==========================================================================
§Send messages styles
========================================================================== */

  &__send-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-color;
    text-transform: capitalize;
    font-size: $font-size-sm;
    line-height: $font-size-sm;
    height: 60px;
    align-self: end;

    svg {
      margin-right: 2px;
      color: $primary;
    }
  }
  &__send-text {
    display: none;
    @include media-breakpoint-tablet() {
      display: block;
    }
  }
}
