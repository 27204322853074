/* ==========================================================================
Video room
========================================================================== */
.video-room {
  &__icon-text {
    text-align: left;
    color: $error;
    &--success {
      color: $success;
    }
    svg {
      display: none;
      margin-top: 8px;
      @include media-breakpoint-tablet() {
        display: block;
      }
    }
  }
  &__phone-support {
    display: none;
    @include media-breakpoint-tablet() {
      display: block;
      margin: 40px 0;
    }
  }
  &__close-btn {
    display: none;
    @include media-breakpoint-tablet() {
      display: inline-block;
    }
  }
  &__row {
    flex-flow: column-reverse;
    align-items: center;
    padding-bottom: 120px;
    @include media-breakpoint-tablet() {
      flex-flow: row;
      padding-bottom: 0;
    }
  }
  &__col-left {
    @include media-breakpoint-tablet() {
      background-color: $white;
      margin: 10px 0;
      padding: 20px 25px 35px 25px;
      border-radius: $border-radius-lg;
    }
  }
  &__col-right {
    @include media-breakpoint-laptop() {
      padding-left: 20px;
    }
  }
  &__join {
    margin-top: 20px;
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(180deg, rgba($body-bg, 0) 0%, rgba($body-bg, 1) 100%);
    @include media-breakpoint-tablet() {
      align-items: flex-start;
      position: relative;
      bottom: initial;
      left: initial;
      width: auto;
      background-color: transparent;
      padding-right: 0px;
      padding-left: 0px;
      padding-bottom: 0px;
    }
    &-btn {
      margin-bottom: 10px;
      @include media-breakpoint-tablet() {
        margin-bottom: 20px;
      }
    }
    &-error {
      margin-top: -0;
      margin-bottom: 10px;
      color: $error;
      font-weight: bold;
      @include media-breakpoint-tablet() {
        margin-bottom: 20px;
      }
    }
    &-members {
      font-size: 14px;
      display: block;
      text-align: center;
      @include media-breakpoint-tablet() {
        text-align: left;
        margin-left: 28px;
      }
    }
  }
}

