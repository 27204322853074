.therapist {

  &__info-proves-item {
    margin-bottom: 10px;
    @include media-breakpoint-tablet() {
      margin-bottom: 10px;
    }
    &:last-of-type {
      margin-bottom: 0;
      @include media-breakpoint-tablet() {
        margin-bottom: 0;
      }
    }
  }

  &__feedback {
    background-color: $dawn-pink;
    margin-top: 2px;
    margin-bottom: 40px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 12px 0;
    align-items: center;
  }
}

.therapist--appointment {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  //overflow: hidden;
  background-color: $white;
  border-radius: $border-radius-lg;
  @include media-breakpoint-tablet() {
    flex-direction: row;
  }

  .therapist {
    &__link {
      color: $text-color;

      &:hover {
        color: $text-color;
        text-decoration: none;
      }
    }

    &__info-wrapper {
      width: 100%;
      padding: 15px 25px;
      font-size: $font-size-sm;
      text-align: left;

      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;



      @include media-breakpoint-tablet() {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-basis: 200px;
        min-width: 200px;
        max-width: 200px;
        padding: 16px 20px;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius-lg;
      }
      @include media-breakpoint-laptop() {
        flex-basis: 275px;
        min-width: 275px;
        max-width: 275px;
      }
      @include media-breakpoint-desktop() {
        flex-basis: 320px;
        min-width: 320px;
        max-width: 320px;
      }
    }
    &__info-wrapper--client-view {
      display: flex;
      justify-content: center;
    }
    &__col-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      text-align: center;
    }
    &__info-col {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      @include media-breakpoint-tablet() {
        display: block;
        max-width: none;
        text-align: center;
      }
      @include media-breakpoint-laptop() {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
      }
    }
    &__info-col--image-wrapper {
      width: 99px;
      height: 99px;
      cursor: pointer;
    }
    &__info-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
    &__info-content {
      display: flex;
      flex-flow: column nowrap;
      margin-top: 10px;
      text-align: center;
    }
    &__info-name {
      font-size: $font-size-base;
      cursor: pointer;
      transition: color 0.2s;
      @include media-breakpoint-laptop() {
        margin-top: 0;
      }

      &:hover {
        color: $primary;
      }
      // exception for markdown
      p {
        margin-bottom: 0;
      }
    }
    &__info-hours {
      font-weight: 400;
      font-size: $font-size-sm;
    }
    &__info-proves {
      display: flex;
      justify-content: center;
      margin: 0 -10px;
      margin-top: 20px;
      font-size: inherit;
      @include media-breakpoint-tablet() {
        display: block;
        margin-top: 30px;
      }
    }
    &__info-proves-item {
      margin-bottom: 15px;
      padding: 0 10px;
      line-height: 18px;
      @include media-breakpoint-tablet() {
        margin-bottom: 15px;
      }
      &:last-of-type {
        margin-bottom: 0;
        @include media-breakpoint-tablet() {
          margin-bottom: 0;
        }
      }
    }
    &__content {
      width: 100%;
      padding: 32px 34px;
      &--past {
        padding-left: 40px;
        @include media-breakpoint-tablet() {
          padding-left: 40px;
        }
      }
    }
    &__content-heading {
      display: flex;
      align-items: flex-start;
      width: 100%;
      font-size: 20px;
      @include media-breakpoint-tablet() {
        flex-flow: row wrap;
      }
    }

    &__date-wrapper {
      display: flex;
      flex-flow: row wrap;
      margin-bottom: 15px;
      font-size: 18px;
      justify-content: space-between;

      @include media-breakpoint-tablet() {
        flex-flow: initial;
        justify-content: left;
      }
    }
    &__date-string {
      display: flex;
      align-items: center;
      flex-basis: 100%;
      padding-bottom: 20px;
      @include media-breakpoint-tablet() {
        flex-basis: auto;
        padding: 0;
      }
    }

    &__date-time {
      display: flex;
      align-items: center;
      flex: 1;
      flex-basis: 100%;
      @include media-breakpoint-tablet() {
        flex-basis: auto;
        max-width: 300px;
        justify-content: center;
      }
    }

    &__invoice {
      display: flex;
      align-items: center;
      flex: 1;
      flex-basis: 100%;
      @include media-breakpoint-tablet() {
        flex-basis: auto;
        justify-content: center;
        font-size: $font-size-sm;
      }
    }

    &__appointment-text {
      display: block;
      margin-bottom: 15px;
    }
    &__button-text {
      max-width: 280px;
      margin-bottom: 22px;
      @include media-breakpoint-desktop() {
        margin-bottom: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      .btn {
        width: 100%;
        min-width: 180px;
      }
      .small {
        font-size: 12px;
      }
      .btn__text {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}

/* ===================================================
   ==== Modifier: Simple card  =======================
   =================================================== */
.therapist--simple {
  align-items: center;
  .therapist {
    &__info-wrapper {
      padding: 25px;
    }
    &__row {
      margin: 0 -10px;
      text-align: center;
      @include media-breakpoint-tablet() {
        display: flex;
        align-items: center;
        text-align: left;
      }
    }
    &__col {
      padding: 0 10px;
    }
    &__col--fixed {
      flex: 1;
      flex-basis: 220px;
      max-width: 250px;
      margin: 0 auto;
      margin-bottom: 10px;
      @include media-breakpoint-tablet() {
        margin-bottom: 0;
      }
    }
    &__col--stretched {
      flex: 2;
    }
    &__content-heading {
      font-size: 18px;
    }
    &__date-day {
      font-size: $font-size-base;
    }
    &__appointment-text {
      margin-bottom: 0;
      line-height: 18px;
    }
  }
}

/* ===========================================================
   ==== Modifier: Role therapist, client view ================
   =========================================================== */

.therapist__info-wrapper--client-view {
  cursor: pointer;

  .therapist__info-col {
    text-align: center;
  }
}

/* ===========================================================
   ==== Modifier: Role client, therapist view ================
   =========================================================== */
.therapist__title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @include media-breakpoint-tablet() {
    margin-bottom: 24px;
  }
  > h1.app-title {
    margin-bottom: 0;
  }
}

.therapist__info-wrapper--therapist-view {
  .therapist__col-wrapper {
    flex-flow: row nowrap;
    @include media-breakpoint-tablet() {
      flex-flow: column nowrap;
    }
    @include media-breakpoint-desktop() {
      flex-flow: row nowrap;
    }
  }
  .therapist__info-content {
    margin-top: 0;
    margin-left: 20px;
    text-align: left;
    @include media-breakpoint-tablet() {
      margin-top: 15px;
      margin-left: 0;
      text-align: center;
    }
    @include media-breakpoint-desktop() {
      margin-top: 0;
      margin-left: 20px;
      text-align: left;
    }
  }
}

// Extra, because of bad styling (lot of changes)
.therapist--appointment {
  .therapist__info-wrapper--therapist-view {
    @media screen and (max-width: 320px) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

/* ===========================================================
   ==== Modifier: ReviewsList ================
   =========================================================== */
.therapist {
  &__reviews-icons-wrapper {
    display: flex;
    margin-bottom: 20px;
    > div:first-child {
      padding-right: 40px;
    }
    .icon-text {
      font-weight: bold;
    }
  }
  &__positive-review-text {
    font-size: 18px;
    line-height: 26px;
    @include media-breakpoint-tablet() {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

// Minified therapist Profile name
.therapist-card__name {
  .therapist-name {
    text-align: left;
  }
}

.therapist-card__item--loader {
  .therapist-card__profile {
    max-height: 136px;
  }
  .therapist-card__price {
    max-height: 30px;
  }
}

.therapist-card {
  &__wrapper {
    box-shadow: 0 7px 20px rgba(211, 155, 123, 0.3);
  }

}

.therapist-card__specialization-item {
  color: $text-color-light;
  background: $bordeaux-background;
}