/* ==========================================================================
Components: Button
========================================================================== */
.btn {
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);
  display: inline-block;
  color: $body-color;
  font-weight: $btn-font-weight;
  font-family: $btn-font-family;
  white-space: $btn-white-space;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  vertical-align: middle;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  user-select: none;

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    @include box-shadow(none);
    opacity: $btn-disabled-opacity;
  }

  &:not(:disabled):not(.disabled) {
    cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

    @include hover() {
      color: $body-color;
      text-decoration: none;
    }

    &:active,
    &.active {
      @include box-shadow($btn-active-box-shadow);

      &:focus {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }
  }
}

//
// Alternate buttons
//

@each $color, $value in $button-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn-primary {
  color: $white;
  box-shadow: $btn-box-shadow;
  &.disabled,
  &:disabled {
    color: $white;
  }
  &:not(:disabled):not(.disabled) {
    &:hover {
      color: $white;
      background-color: $brown;
      border-color: $brown;
      box-shadow: none;
    }
  }
  &:focus {
    color: $white;
  }
}

.btn-outline-primary {
  &:not(:disabled):not(.disabled) {
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

.btn-sm {
  padding: $btn-sm-padding-y $btn-sm-padding-x;
  font-size: $btn-sm-font-size;
}


.btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 34px;
  text-transform: uppercase;
  transition: 0.3s;
  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }
  &:not(:disabled):not(.disabled) {
    &:hover {
      color: $aubergine;
      text-decoration: none;
    }
  }
  &__icon {
    width: 25px;
    height: 21px;
    margin-left: 22px;
  }
}

.btn-login {
  padding: 5px 10px;
  color: $text-color-light;
  font-size: $font-size-sm;
  line-height: $font-size-sm;
  text-transform: none;
  background-color: $dawn-pink;
  &:not(:disabled):not(.disabled) {
    &:hover {
      color: $text-color-light;
      background-color: $white;
    }
  }
}
