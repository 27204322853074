/* ==========================================================================
Site footer
========================================================================== */
.site__footer {
  position: relative;
  z-index: 20;
  padding: 54px 20px;
  color: $white;
  background-color: $wood-bark;
  @include media-breakpoint-up(md) {
    padding: 100px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 145px 0;
  }
  .wave--top {
    margin-bottom: -1px;
  }

  // ======================= Wrapper
  &-wrapper {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-flow: row;
    }
  }

  // ======================= Info
  &-info {
    display: flex;
    flex-flow: column nowrap;
    @include media-breakpoint-up(lg) {
      flex-basis: 290px;
      max-width: 290px;
      margin-right: 40px;
    }
    @include media-breakpoint-up(xl) {
      margin-right: 160px;
    }
  }

  // ======================= Logo
  &-logo {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    text-align: center;
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      order: 1;
    }
    @include media-breakpoint-up(lg) {
      align-items: flex-start;
    }
    &-img {
      width: 190px;
      max-height: 65px;
    }
  }

  &-language-switcher {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-top: 30px;
    .language-switcher__label {
      display: none;
    }
  }

  // ======================= Image links
  &-image-links {
    margin: -10px -10px 10px;
    text-align: center;
    @include media-breakpoint-up(md) {
      order: 3;
      margin-top: 40px;
      margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }
  &-image-link {
    &:hover {
      text-decoration: none;
    }
  }
  &-image {
    max-height: 40px;
    margin: 10px;
  }

  // ======================= Social sites
  &-social {
    margin: 28px 0;
    @include media-breakpoint-up(md) {
      order: 4;
      margin-top: 55px;
      margin-bottom: 50px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
    }
    &-list {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      list-style-type: none;
      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }
    }
    &-item {
      width: 80px;
      height: 80px;
      margin: 0 10px;
      background-color: $rebel;
      border-radius: 99px;
      transition: 0.3s;
      @include media-breakpoint-up(lg) {
        width: 64px;
        height: 64px;
      }
      &:first-of-type {
        @include media-breakpoint-up(lg) {
          margin-left: 0;
        }
      }
      &:last-of-type {
        @include media-breakpoint-up(lg) {
          margin-right: 0;
        }
      }
      &:hover {
        background-color: $primary;
      }
    }
    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: $white;
      text-align: center;
      text-decoration: none;
      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
    &-icon {
      width: 24px;
      height: 24px;
      fill: $white;
    }
  }

  // ======================= Contacts
  &-contacts {
    max-width: 250px;
    margin: 0 auto;
    font-size: 18px;
    @include media-breakpoint-up(md) {
      order: 2;
      margin-top: 55px;
    }
    @include media-breakpoint-up(lg) {
      max-width: initial;
      margin-right: 0;
      margin-left: 0;
    }
    a {
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  // ======================= Menus
  &-menus {
    display: flex;
    flex-basis: 250px;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 250px;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    @include media-breakpoint-up(md) {
      flex: 1;
      flex-flow: row wrap;
      max-width: initial;
      margin-right: auto;
      margin-left: auto;
    }
    @include media-breakpoint-up(lg) {
      justify-content: space-between;
      margin-top: 17px;
    }
  }

  // ======================= Box
  &-box {
    margin-bottom: 60px;
    @include media-breakpoint-up(md) {
      flex-basis: 33.3333%;
      max-width: 33.3333%;
    }
    @include media-breakpoint-up(lg) {
      flex-basis: initial;
      max-width: initial;
      margin-bottom: 38px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &-title {
      margin-bottom: 35px;
      color: $white;
      font-weight: 700;
      font-size: 18px;
    }
  }

  // ======================= Navigation
  &-nav {
    margin: 0;
    padding: 0;
    list-style-type: none;
    &-item {
      margin-bottom: 30px;
      font-size: 14px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 22px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      &-link {
        color: $mountain-mist;
        transition: 0.3s;
        &:hover {
          color: $white;
          text-decoration: none;
        }
      }
    }
  }

  // ======================= Copyright
  &-copy {
    position: relative;
    padding: 27px 0;
    color: $mountain-mist;
    font-size: 14px;
    @include media-breakpoint-up(md) {
      display: flex;
      flex-basis: 100%;
      flex-flow: column nowrap;
      justify-content: center;
      max-width: 100%;
    }
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      padding: 0;
      padding-top: 65px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: calc(100% + 32px);
      height: 2px;
      background-color: $rebel;
      transform: translate(-50%, 0%);
      content: '';
      @include media-breakpoint-up(lg) {
        width: 100%;
      }
    }
    &-wrapper {
      display: flex;
      flex-flow: column wrap;
      width: 100%;
      @include media-breakpoint-up(md) {
        flex-flow: row wrap;
      }
    }
    span {
      display: block;
      margin-bottom: 19px;
      @include media-breakpoint-up(md) {
        margin-right: 30px;
      }
    }
    a {
      display: block;
      color: $mountain-mist;
      transition: 0.3s;
      @include media-breakpoint-up(md) {
        margin-left: 30px;
      }
      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }
  &-notice {
    margin-top: 10px;
  }

  // ======================= Payment methods
  &-payment-methods {
    flex-basis: 100%;
  }
  &-payment-method {
    width: auto;
    margin: 10px 20px 10px 0;
  }
  &-payment-method,
  &-payment-method-link {
    display: inline-block;
    vertical-align: middle;
  }
}
