/* ==========================================================================
Components: Wave
========================================================================== */
@each $color, $value in $bg-colors {
  .wave--#{$color} {
    fill: $value;
  }
}

.wave {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  &--top {
    top: -45px;
    height: 45px;
    transform: translate(-50%, 0%) scaleY(-1);
    @include media-breakpoint-up(lg) {
      top: -112px;
      height: 113px;
    }
  }
  &--bottom {
    bottom: -45px;
    height: 45px;
    @include media-breakpoint-up(lg) {
      bottom: -112px;
      height: 113px;
    }
  }
}
