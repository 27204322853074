.howItWorks {
  &__icon {
    &--wrapper {
      background: $white;
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-tablet() {
        width: 120px;
        height: 120px;
      }

      @include media-breakpoint-big-tablet() {
        width: 180px;
        height: 180px;
      }
    }
  }

  &__listItem {
    padding: 32px;
    background: #F5F0EB;
    border-radius: 12px;

    @include media-breakpoint-tablet() {
      padding: 32px 100px 32px 32px;
    }
  }
}

.dots-bg {
  position: relative;

  :after {
    content: "";
    position: absolute;
    width: 11px;
    height: 79px;
    display: block;
    left: calc(50% - 5px);
    top: 120%;
    background: radial-gradient(circle, #{$light-brown} 1px, transparent 1px) repeat-y;
    background-size: 18px 18px;
  }

  @include media-breakpoint-tablet() {
    :after {
      top: 88%
    }
  }
}
