.btn-group {
  width: 100%;
  margin-bottom: 25px;

  &--small {
    width: auto;
  }

  &__item {
    padding: 5px 0;
    border: 1px solid $light-brown;
    @include media-breakpoint-laptop {
      padding: 10px 0;
    }
    &:hover {
      background-color: $light-grey;
      border-left: 1px solid inherit;
    }
    &:first-child {
      border-top-left-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
    }
    &:last-child {
      border-left: 0;
      border-top-right-radius: $border-radius-sm;
      border-bottom-right-radius: $border-radius-sm;
    }
    &:not(:first-child):not(:last-child) {
      border-left: hidden;
    }
    &-title {
      margin-bottom: 10px;
      text-align: center;
      span {
        display: block;
        color: $text-color-light;
        font-size: $font-size-sm;
        line-height: $font-size-base;

        @include media-breakpoint-tablet() {
          font-size: $font-size-base;
          line-height: 20px;
        }
      }
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  &__buttons {
    width: 100%;
  }
}

/* Hard rewrite */
.btn-group > .btn:not(:first-child),
body .reservanto-widget .btn-group > .reservanto-button:not(:first-child),
.post-password-form .btn-group > input:not(:first-child)[type='submit'],
.btn-group > .btn-group:not(:first-child) {
  margin-left: 0;
}
