.btn {
  &__text {
    margin-top: 20px;
    line-height: 16px;
  }
  &__icon {
    margin-right: 2px;
    margin-left: -2px;
    vertical-align: bottom;
  }
  &__close {
    rect {
      fill: $text-color;
    }
  }
}

.btn-dark {
  background-color: $brown;
  color: $white;

  &:hover {
    background-color: $black;
  }

  &:not(:disabled):not(.disabled):hover {
    color: $white;
  }
}

.btn-brownies {
  background-color: $brownies;
  color: $white;
}

.btn-white {
  background-color: $white;
  color: $text-color;

  &:not(:disabled):not(.disabled):hover {
    color: $black;
  }
}

.btn-light {
  background-color: $light;
  color: $text-color-light;
  &:not(:disabled):not(.disabled):hover {
    color: $text-color;
  }
}

.btn-secondary {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;

  &:not(:disabled):not(.disabled):hover {
    color: $white;
  }
}

.btn-link-inline {
  color: $primary;
  background-color: transparent;
  border: 0;
  &:focus {
    outline: none;
  }
}

.btn-link-inline-underline {
  text-decoration: underline;
  &.btn-link:not(:disabled):not(.disabled):hover {
    &.btn-link-inline-underline {
      &:hover {
        color: $text-color;
      }
    }
  }
}

.button-link {
  padding: 0;
  color: $primary;
  font-weight: 700;
  font-size: $font-size-base;
  background-color: transparent;
  border: 0;
  transition: color 0.2s;
  &:hover {
    color: $text-color;
  }
}

.button-link--inverted {
  color: $text-color;
  &:hover {
    color: $primary;
  }
}

.btn-outline-primary {
  &:hover {
    .icon {
      color: inherit;
    }
  }
  &:not(:disabled):not(.disabled):focus {
    &:focus {
      color: $primary;
    }
  }
}

.btn-loading {
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: left 10px center;
  background-size: 30px auto;
  transition: 0.2s;
  &.btn-primary {
    @include bg-loader($white, lighten($primary, 10%));
  }
  &.btn-light {
    @include bg-loader($white, lighten($light, 10%));
  }
}

.back-button {
  &.btn-link {
    padding: 0;
  }
  &__back-icon {
    margin-right: 10px;
  }
  &__text {
    &--sm {
      font-size: 20px;
    }
  }
}

.btn-icon {
  border: none;
  border-radius: 6px;
  padding: 8px;
  background: transparent;
  color: $text-color;

  &--light {
    background: $light;
  }
}
