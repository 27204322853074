.language-switcher {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  color: $body-color;
  border: 2px solid $light;
  border-radius: 99px;
  cursor: pointer;
  &:hover {
    color: $body-color;
    background-color: $white;
    border: 2px solid transparent;
  }
  &__globe {
    color: $body-color;
  }
  &--active {
    color: $body-color !important;
    border: 2px solid transparent;
  }
  &__button {
    padding: 0;
    border: 0;
    background-color: transparent;
    color: inherit;
    &:focus {
      outline: none;
    }
  }
  &__label {
    vertical-align: text-top;
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  &__globe {
    padding: 0 16px;
  }
  &__caret {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    display: none;
    width: 56px;
    height: 35px;
    background-color: $white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    transform: translate(-50%, 0);
    &::before {
      position: absolute;
      right: 100%;
      bottom: 0;
      width: 15px;
      height: 11px;
      background-image: url('/wp-content/themes/hedepy/assets/icons/shape.caret.svg');
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
    }
    &--active {
      display: block;
    }
    &--footer {
      display: block;
      background-color: $salmon;
      &::before {
        color: $salmon;
      }
      &--active {
        display: block;
      }
    }
  }
  &__list {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    display: none;
    width: 150px;
    padding: 13px 8px;
    list-style: none;
    background-color: $white;
    border-radius: 12px;
    border-top-right-radius: 0;
    transform: translate(2px, 2px);
    &--active {
      display: block;
    }
    &--footer {
      background-color: $salmon;
    }
  }
  &__item {
    width: 100%;
  }
  &__link {
    display: block;
    width: 100%;
    padding: 4px 8px;
    color: $light-brown !important;
    font-size: 13px;
    &:hover {
      color: $text-color-light;
      text-decoration: none;
      background-color: $sand;
      border-radius: 4px;
    }
  }
  &__link--active {
    background-color: $sand;
    @include media-breakpoint-up(xl) {
      background-color: transparent;
    }
  }
  &__flag {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }

  // Because we don't want to use !important
  .language-switcher__btn {
    &:hover {
      text-decoration: none;
    }
  }

  &__title {
    margin-bottom: 8px;
    color: $aluminium;
    font-size: 13px;
    line-height: 20px;
  }

  &__icon {
    width: 20px;
    height: 20px;

    &--arrow {
      width: 14px;
      height: 14px;
    }

    &--globe {
      color: $primary;
    }
  }

  &__wrapper {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  &__btn {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 200px;
    height: 53px;
    padding: 0 23px;
    color: $white;
    text-decoration: none;
    border: 1px solid $woody-brown;
    border-radius: 99px;
    transition: 0.3s;

    &:hover {
      color: $white;
      background-color: $woody-brown;
    }

    &-name {
      margin: 0 15px;
    }
  }
}

.language-switcher--header {
  .language-switcher {
    &__btn {
      min-width: initial;
      height: 32px;
      padding: 0 14px;
      color: $text-color-light;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      text-transform: lowercase;
      border-color: $dawn-pink;
      &:hover {
        background-color: $dawn-pink;
      }
      &-name {
        margin: 0 10px;
      }
    }
    &__icon {
      width: 16px;
      height: 16px;
      color: $text-color-light;
      &--arrow {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.language-switcher--footer {
  color: $white;
  &:hover {
    border: 2px solid transparent;
  }
  &--active {
    border: 2px solid transparent;
  }
  .language-switcher__link {
    &:hover {
      text-decoration: none;
    }
  }
  .language-switcher__list {
    right: initial;
    left: -4px;
    border-radius: 12px;
    border-top-left-radius: 0;
  }
  .language-switcher__caret {
    &::before {
      right: initial;
      left: 100%;
      transform: scaleX(-1);
    }
  }
}
